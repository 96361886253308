import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Clear from "@mui/icons-material/Clear";
import { DeleteDialog } from "../components/DeleteDialog";
import { Link } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { setDoc, doc } from "firebase/firestore";
import { Context } from "context/Wrapper";
import { db } from "config/config";
import { listenAndFilterTo, useAdvancedDataListener } from "apis/dataSnapshots";
import AdvancedTable from "components/AdvancedTable";
import useCustomParams from "components/shared/useCustomParams";
import tableConfig from "config/advancedTableColumnsControl.json";

const searchLimit = tableConfig?.searchMinLength;

// const ListItem = styled(Paper)(({ theme, ...rest }) => {
//   return {
//     width: "100%",
//     padding: theme.spacing(1),
//     textAlign: "left",
//     marginTop: theme.spacing(2),
//     marginBottom: 0,
//     color: theme.palette.primary.main,
//     background: rest?.userGroupCheck
//       ? theme.palette.secondary.secondary
//       : theme.palette.secondary.main,
//   };
// });

let filterSearchedTimeout = null;

export const Contacts = (props) => {
  const context = useContext(Context);
  const { params, updateParams } = useCustomParams();

  const { groupId, isPro } = context?.state?.userProfile || {};
  const user = context?.state?.userProfile || {},
    { showAllDataFlag } = context?.state || {};

  // const { db, user, groupId, allData, isPro } = props;

  const [contacts, setContacts] = useState([]);

  const navigate = useNavigate();

  const [deleteOpen, setDeleteOpen] = useState(false);

  const [itemToDelete, setItemToDelete] = useState({
    itemId: "",
    identifier: "",
  });

  const [filteredContacts, setFilteredContacts] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const { data, count } = useAdvancedDataListener({
    user,
    // conditions,
    // setLastRef: setLastVisible,
    condition: groupId && isPro && showAllDataFlag,
    collectionName: "contacts",
    // rowsLimit,
    // params,
  });

  useEffect(() => {
    updateParams({
      page: "",
      search: "",
      pageIncreasing: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setContacts(data);
  }, [contacts, data]);

  useEffect(() => {
    if (
      (searchValue?.length < searchLimit && searchValue?.length !== 0) ||
      !searchValue
    ) {
      return;
    }
    if (filterSearchedTimeout !== null) clearTimeout(filterSearchedTimeout);

    filterSearchedTimeout = setTimeout(() => {
      updateParams({ search: searchValue, pageSize: params.get("pageSize") });

      const newArr = contacts
        .filter(
          (item) =>
            item.fullName?.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.businessName
              ?.toLowerCase()
              .includes(searchValue.toLowerCase()) ||
            item.phone?.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.email?.toLowerCase().includes(searchValue.toLowerCase())
        )
        .map((item) => {
          let newFullName =
            item.fullName &&
            item.fullName.replace(
              new RegExp(searchValue, "gi"),
              (match) =>
                `<mark style="background: #2769AA; color: white;">${match}</mark>`
            );
          let newPhone =
            item.phone &&
            item.phone.replace(
              new RegExp(searchValue, "gi"),
              (match) =>
                `<mark style="background: #2769AA; color: white;">${match}</mark>`
            );
          let newBusinessName =
            item.businessName &&
            item.businessName.replace(
              new RegExp(searchValue, "gi"),
              (match) =>
                `<mark style="background: #2769AA; color: white;">${match}</mark>`
            );
          let newEmail =
            item.email &&
            item.email.replace(
              new RegExp(searchValue, "gi"),
              (match) =>
                `<mark style="background: #2769AA; color: white;">${match}</mark>`
            );
          return {
            ...item,
            fullName: newFullName,
            phone: newPhone,
            businessName: newBusinessName,
            email: newEmail,
          };
        });

      setFilteredContacts(newArr);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts, searchValue]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  const createMarkup = (html) => {
    return { __html: html };
  };

  const handleTrackChange = async (event, id) => {
    const thisContact = doc(db, "contacts", id);
    setDoc(thisContact, { track: event.target.checked }, { merge: true }).then(
      () => {
        console.log("updated");
      }
    );
  };

  const handleDelete = (id, identifier, row) => {
    console.log(row);
    setItemToDelete({ itemId: id, identifier: row.fullName });
    setDeleteOpen(true);
  };

  const confirmDelete = async () => {
    db.collection("contacts").doc(itemToDelete.itemId).delete();
    setItemToDelete({ itemId: "", identifier: "" });
    setDeleteOpen(false);
    navigate("/contacts");
  };

  const searchCheck = searchValue.length >= searchLimit;
  const contactCollection = searchCheck ? filteredContacts : contacts;

  return (
    <>
      <Fade in={true}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={8} style={{ position: "sticky" }}>
              <Typography align="left" variant="h4">
                CONTACTS
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Paper
                component="form"
                onSubmit={(e) => e.preventDefault()}
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginLeft: "auto",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  value={searchValue}
                  inputProps={{ "aria-label": "search" }}
                  onInput={handleSearch}
                />
                {searchCheck ? (
                  <IconButton
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => {
                      setSearchValue("");
                      updateParams({ search: "" });
                    }}
                  >
                    <Clear />
                  </IconButton>
                ) : (
                  <IconButton sx={{ p: "10px" }} aria-label="clear">
                    <SearchIcon />
                  </IconButton>
                )}
              </Paper>
            </Grid>
          </Grid>

          {/* <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={2}
            textAlign={"left"}
            padding={"1rem 1rem 0 1rem"}
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Grid item xs={12} md={3} lg={3}>
              <span>Name</span>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <span>Business</span>
            </Grid>
            <Grid item xs={12} md={2} lg={2}>
              <span>Phone</span>
            </Grid>
            <Grid item xs={12} md={3} lg={3}>
              <span>Email</span>
            </Grid>
            
            <Grid item xs={12} md={1} lg={1}>
              <span>Delete</span>
            </Grid>
          </Grid>
          {contactCollection.map((contact) => (
            <ListItem
              userGroupCheck={
                contact?.userId !== context?.state?.userProfile?.userId &&
                contact?.groupId === context?.state?.userProfile?.groupId
              }
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={12} md={3} lg={3}>
                  <Button
                    variant="contained"
                    style={{ width: "100%", justifyContent: "flex-start" }}
                    color={"primary"}
                    component={Link}
                    to={`/contacts/details/${contact.id}`}
                  >
                    <span
                      dangerouslySetInnerHTML={createMarkup(contact.fullName)}
                    />
                  </Button>
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <span
                    dangerouslySetInnerHTML={createMarkup(contact.businessName)}
                  />
                </Grid>
                <Grid item xs={12} md={2} lg={2}>
                  <span dangerouslySetInnerHTML={createMarkup(contact.phone)} />
                </Grid>
                <Grid item xs={12} md={3} lg={3}>
                  <span dangerouslySetInnerHTML={createMarkup(contact.email)} />
                </Grid>
              
                <Grid item xs={1} md={1} lg={1}>
                  <IconButton>
                    <Delete
                      onClick={() => handleDelete(contact.id, contact.fullName)}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </ListItem>
          ))} */}

          <AdvancedTable
            data={contactCollection}
            collectionName="contacts"
            columnsRaw={[
              // {
              // field: "id",
              // headerName: "ID",
              // },
              {
                field: "fullName",
                headerName: "Name",
                renderCell: ({ row }) => (
                  <div dangerouslySetInnerHTML={{ __html: row.fullName }}></div>
                ),
              },
              {
                field: "businessName",
                headerName: "Business",
                renderCell: ({ row }) => (
                  <div
                    dangerouslySetInnerHTML={{ __html: row.businessName }}
                  ></div>
                ),
              },
              {
                field: "phone",
                headerName: "Phone",
                renderCell: ({ row }) => (
                  <div dangerouslySetInnerHTML={{ __html: row.phone }}></div>
                ),
              },
              {
                field: "email",
                headerName: "Email",
                renderCell: ({ row }) => (
                  <div
                    dangerouslySetInnerHTML={{ __html: row.buildingString }}
                  ></div>
                ),
              },
            ]}
            rowCount={searchCheck ? contactCollection?.length : count}
            // rowsLimit={rowsLimit}
            handleDelete={handleDelete}
          />
          <Box
            sx={{
              position: "fixed",
              bottom: "1rem",
              left: "3.5rem",
              "& > :not(style)": { m: 1 },
            }}
          >
            {!props.isInactive && (
              <Link to="/contacts/new">
                <Fab
                  color="primary"
                  aria-label="add"
                  variant="extended"
                  disabled={props.isInactive}
                >
                  <AddIcon sx={{ mr: 1 }} />
                  New
                </Fab>
              </Link>
            )}
          </Box>
        </div>
      </Fade>
      <DeleteDialog
        open={deleteOpen}
        confirmDelete={confirmDelete}
        setDialogOpen={setDeleteOpen}
        identifier={itemToDelete.identifier}
      />
    </>
  );
};
