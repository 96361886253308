// InfiniteScrollSelect.js
import { useState, useEffect, useMemo, useContext, useRef } from "react";
import {
  TextField,
  MenuItem,
  CircularProgress,
  Select,
  InputLabel,
  FormControl,
  ListSubheader,
  InputAdornment,
  IconButton,
} from "@mui/material";
// fetchData.js
import {
  collection,
  query,
  orderBy,
  limit,
  startAfter,
  getDocs,
  documentId,
  where,
  or,
  and,
  getCountFromServer,
} from "firebase/firestore";
import { checkSearchLnth, db } from "config/config";
import { Search } from "@mui/icons-material";
import { Context } from "context/Wrapper";
import { GridClearIcon } from "@mui/x-data-grid";
import tableConfig from "config/advancedTableColumnsControl.json";

let searchTimeout = null,
  filterSearchedTimeout = null;
const searchLimit = tableConfig?.searchMinLength;

export const fetchData = async ({
  lastVisible,
  collectionName,
  searchText = "",
  conditions = [],
  label,
  batchSize = 10,
}) => {
  let q;

  if (conditions?.length === 0) return { data: [], lastVisible: null };

  if (lastVisible)
    q = query(
      collection(db, collectionName),
      orderBy(documentId(), "desc"),
      startAfter(lastVisible),
      limit(batchSize),
      ...conditions
    );
  else
    q = query(
      collection(db, collectionName),
      orderBy(documentId(), "desc"),
      limit(batchSize),
      ...conditions
    );

  let count = 0;

  if (`${searchText}`.trim()?.length > searchLimit) {
    q = query(
      collection(db, collectionName),
      orderBy(label, "desc"),
      orderBy(documentId(), "desc"),
      // and(
      //   where(label, ">=", searchText),
      //   where(label, "<=", searchText + "\uf8ff"),
      ...conditions
      // )
    );
    count = (await getCountFromServer(q))?.data()?.count;
  } else
    count = (
      await getCountFromServer(
        query(collection(db, collectionName), ...conditions)
      )
    )?.data()?.count;

  const snapshot = await getDocs(q);
  const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  const lastVisibleDoc =
    searchText?.length > 0 ? null : snapshot.docs[snapshot.docs.length - 1];
  return { data, lastVisible: lastVisibleDoc, count };
};

const InfiniteScrollSelect = ({
  collectionName = "",
  label = "name",
  inputLabel,
  onChange,
  value,
  name,
  data,
}) => {
  const selectElem = useRef(null);
  const context = useContext(Context);

  const { groupId, isPro, userId } = context?.state?.userProfile || {};
  const { showAllDataFlag } = context?.state || {};

  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastVisible, setLastVisible] = useState(null);
  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [count, setCount] = useState(0);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  useEffect(() => {
    setOptions(data);
  }, [data]);

  useEffect(() => {
    if (searchText?.length < searchLimit && searchText?.length !== 0) return;
    if (filterSearchedTimeout !== null) clearTimeout(filterSearchedTimeout);

    filterSearchedTimeout = setTimeout(() => {
      const newArr = options
        ?.filter((item) =>
          item?.[label]?.toLowerCase().includes(searchText.toLowerCase())
        )
        .map((item) => {
          let newValue = item?.[label]?.replace(
            new RegExp(searchText, "gi"),
            (match) =>
              `<mark style="background: #2769AA; color: white;">${match}</mark>`
          );

          return {
            ...item,
            [label]: newValue,
          };
        });

      setFilteredOptions(newArr);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, searchText]);

  useEffect(() => {
    setAllDataLoaded(false);
  }, [showAllDataFlag]);

  useEffect(() => {
    // if (count === options?.length && count !== 0) return;
    if (allDataLoaded) return;

    setOptions([]);
    setLastVisible(null);
    loadOptions({ lastVisible: null, options: [] });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [`${showAllDataFlag}`, searchText, `${userId}`]);

  const loadOptions = async (props) => {
    setLoading(true);

    const {
      data,
      lastVisible: newLastVisible,
      count,
    } = await fetchData({
      lastVisible:
        props?.lastVisible !== undefined ? props?.lastVisible : lastVisible,
      collectionName,
      searchText,
      label,
      conditions:
        userId && groupId && isPro && showAllDataFlag
          ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
          : userId
            ? [where("userId", "==", userId)]
            : [],
    });

    if (searchText?.length > searchLimit) {
      setOptions(data);
      setAllDataLoaded(true);
    } else
      setOptions((prevOptions) => [
        ...(props?.options || prevOptions),
        ...data.filter(
          (item) =>
            !prevOptions.some((prevItem) => prevItem[label] === item[label])
        ),
      ]);

    setCount(count || 0);
    setLastVisible(newLastVisible);
    setLoading(false);
  };

  const handleOpen = () => {
    setOpen(true);
    if (options?.length >= count && options?.length !== 0) return;

    if (options.length === 0) {
      loadOptions();
    }
  };

  const handleScroll = (event) => {
    if (options?.length >= count) return;
    const bottom =
      event.target.scrollHeight - event.target.scrollTop ===
      event.target.clientHeight;
    if (bottom && !loading) {
      loadOptions();
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (searchTimeout !== null) clearTimeout(searchTimeout);
    searchTimeout = setTimeout(() => {
      setSearchText(value);
    }, 500);
  };

  const searchCheck = searchText?.length > searchLimit;

  const optionsCollection = searchCheck ? filteredOptions : options;
  return (
    <FormControl fullWidth>
      <InputLabel id={`infinite-select-label-${name}`}>{inputLabel}</InputLabel>
      <Select
        inputRef={selectElem}
        name={name}
        labelId={`infinite-select-label-${name}`}
        label={inputLabel}
        id="infinite-select"
        onOpen={handleOpen}
        onClose={() => {
          setOpen(false);
          setSearchText("");
        }}
        onChange={(...rest) =>
          onChange(
            ...rest,
            optionsCollection?.find(
              (item) => item?.id === rest?.[1]?.props?.value
            )
          )
        }
        value={value}
        MenuProps={{
          PaperProps: {
            onScroll: handleScroll,
            sx: {
              maxHeight: "300px",
            },
          },
          autoFocus: false,
        }}
        endAdornment={
          value?.length > 0 && (
            <InputAdornment sx={{ marginRight: "10px" }} position="end">
              <IconButton
                onClick={() => {
                  if (selectElem.current?.node) {
                    selectElem.current.node.value = "";
                    onChange({ target: selectElem.current.node });
                  }
                }}
              >
                <GridClearIcon />
              </IconButton>
            </InputAdornment>
          )
        }
        // input={<TextField />}
        // onInput={(e) => console.log(e)}
      >
        <ListSubheader>
          <TextField
            size="small"
            // Autofocus on textfield
            autoFocus
            placeholder="Type to search..."
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            onChange={handleSearch}
            onKeyDown={(e) => {
              if (e.key !== "Escape") {
                // Prevents autoselecting item while typing (default Select behaviour)
                e.stopPropagation();
              }
            }}
          />
        </ListSubheader>
        {(optionsCollection || [])?.map((option) => (
          <MenuItem key={option.id} value={option.id} >
            <span dangerouslySetInnerHTML={{ __html: option?.[label] }}></span>
          </MenuItem>
        ))}
        {loading && (
          <MenuItem disabled>
            <CircularProgress size={24} />
          </MenuItem>
        )}
      </Select>
    </FormControl>
  );
};

export default InfiniteScrollSelect;
