import { CircularProgress, Snackbar } from "@mui/material";
import { db } from "config/config";
import {
  collection,
  documentId,
  getCountFromServer,
  getDocs,
  limit,
  onSnapshot,
  or,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import tablesDataControl from "config/advancedTableColumnsControl.json";
import { Context } from "context/Wrapper";
const searchLimit = tablesDataControl?.searchMinLength;

export const listenAndFilterTo = (props) => {
  const { setData, user, condition, setContext, collectionName } = props;

  const conditions = props?.conditions || [],
    setLastRef = props?.setLastRef || (() => { });

  if (!(user?.groupId?.length > 0 && user?.userId?.length > 0)) {
    setData([]);
    return () => { };
  }

  try {
    const q = condition
      ? query(
        collection(db, collectionName),
        or(
          where("groupId", "==", user?.groupId),
          where("userId", "==", user.userId)
        ),
        ...conditions
      )
      : query(
        collection(db, collectionName),
        where("userId", "==", user.userId),
        ...conditions
      );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      if (querySnapshot.docs?.length > 0)
        setLastRef(querySnapshot.docs[querySnapshot.docs.length - 1]);
      const newDocs = querySnapshot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      setData(newDocs);
    });

    return unsubscribe;
  } catch (err) {
    console.log(err);
    setData([]);

    setContext({ snackbar: <Snackbar open={true} message={err?.message} /> });

    setTimeout(() => {
      setContext({ snackbar: null });
    }, 4000);

    return () => { };
  }
};
export const useAdvancedDataListener = ({
  // setData,
  user,
  condition,
  fetchAll,
  // setContext,
  collectionName,
  // conditions = [],
  // setLastRef = () => {},
  // rowsLimit,
  // params,
}) => {
  const context = useContext(Context);

  const [params] = useSearchParams();
  const [data, setData] = useState([]);
  const [count, setCount] = useState(0);
  const [lastVisible, setLastVisible] = useState(null);
  const [allDataLoaded, setAllDataLoaded] = useState(false);

  const extraConditions = fetchAll ? [] : condition
    ? [
      or(
        where("groupId", "==", user?.groupId),
        where("userId", "==", user?.userId)
      ),
    ]
    : [where("userId", "==", user?.userId)];


  useEffect(() => {
    setAllDataLoaded(false);
  }, [condition]);

  useEffect(() => {
    if (!(user?.groupId?.length > 0 && user?.userId?.length > 0)) return;
    fetchTotalItems();
    fetchPage(params?.get("page"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, `${params}`, condition]);

  const fetchTotalItems = async () => {
    const coll = condition
      ? query(collection(db, collectionName), ...extraConditions)
      : query(collection(db, collectionName), ...extraConditions);
    const snapshot = await getCountFromServer(coll);
    const totalItems = snapshot.data().count;
    setCount(totalItems);
  };

  const fetchPage = async (page) => {
    if (allDataLoaded) return;

    context?.setState &&
      context?.setState({
        snackbar: (
          <CircularProgress
            size={46}
            style={{
              position: "fixed",
              right: "10%",
              bottom: "5%",
              zIndex: 9
            }}
          />
        ),
      });

    let queryRef = query(
      collection(db, collectionName),
      orderBy(documentId(), "desc"),
      limit(params.get("pageSize") || tablesDataControl?.defaultRowsLimit),
      ...extraConditions
    );


    if (page > 0 && lastVisible) {
      queryRef = query(
        collection(db, collectionName),
        orderBy(documentId(), "desc"),
        startAfter(lastVisible),
        limit(params.get("pageSize") || tablesDataControl?.defaultRowsLimit),
        ...extraConditions
      );
    }


    if (params.get("search") && params.get("search")?.length >= searchLimit) {
      queryRef = query(
        collection(db, collectionName),
        orderBy(documentId(), "desc"),
        ...extraConditions
      );
      setAllDataLoaded(true);
    }

    const querySnapshot = await getDocs(queryRef);
    const docs = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setData(docs);

    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setTimeout(() => {
      context?.setState({ snackbar: null });
    }, 1000);
  };

  return { data, count };
};

export const useFetchCollectionDocs = ({
  collectionName,
  conditions = [],
  setContext = () => { },
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        if (conditions?.length === 0) return;

        const data = await getDocs(
          query(collection(db, collectionName), ...conditions)
        );

        if (data.empty) return;

        return setData(
          data.docs?.map((doc) => ({
            ...doc?.data(),
            id: doc.id,
          })) || []
        );
      } catch (error) {
        setContext({
          snackbar: <Snackbar open={true} message={error?.message} />,
        });

        setTimeout(() => {
          setContext({ snackbar: null });
        }, 4000);

        console.log("error", error);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionName, JSON.stringify(conditions)]);

  return data;
};
