import "./App.css";
import React, { useEffect, useState } from "react";
import Layout from "./components/Layout";
import Checkbox from "@mui/material/Checkbox";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import tinycolor from "tinycolor2";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";

import CssBaseline from "@mui/material/CssBaseline";

import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import X from "./components/x";
import P from "./components/p";
import D from "./components/d";
import T from "./components/t";
import R from "./components/r";

import mainLogo from "./images/xpdtr.png";

import { auth, db, uiConfig } from "./config/config";
import { doc, setDoc, onSnapshot } from 'firebase/firestore';


import { SubscriptionProvider } from "./context/SubscriptionContext";
import { registerLicense } from "@syncfusion/ej2-base";
import Wrapper from "context/Wrapper";

const primary = "#002977";
const secondary = "#f89d07";
const secondarySecondary = "#f5c178";
const warning = "#931621";
const success = "#629677";
const info = "#931621";

const lightenRate = 7.5;
const darkenRate = 15;

const theme = createTheme({
  palette: {
    primary: {
      main: primary,
      light: tinycolor(primary).lighten(lightenRate).toHexString(),
      dark: tinycolor(primary).darken(darkenRate).toHexString(),
    },
    secondary: {
      main: secondary,
      secondary: secondarySecondary,
      light: tinycolor(secondary).lighten(lightenRate).toHexString(),
      dark: tinycolor(secondary).darken(darkenRate).toHexString(),
      contrastText: "#FFFFFF",
    },
    warning: {
      main: warning,
      light: tinycolor(warning).lighten(lightenRate).toHexString(),
      dark: tinycolor(warning).darken(darkenRate).toHexString(),
    },
    success: {
      main: success,
      light: tinycolor(success).lighten(lightenRate).toHexString(),
      dark: tinycolor(success).darken(darkenRate).toHexString(),
    },
    info: {
      main: info,
      light: tinycolor(info).lighten(lightenRate).toHexString(),
      dark: tinycolor(info).darken(darkenRate).toHexString(),
    },
    text: {
      primary: "#4A4A4A",
      secondary: "#6E6E6E",
      hint: "#B9B9B9",
    },
    background: {
      default: "#FAFAFA",
      light: "#F3F5FF",
    },
    action: {
      active: primary,

      hover: secondary,

      focus: secondary,

      selected: secondary,
    },
  },
  typography: {
    h4: {
      color: primary,
      fontFamily: "Roboto",
      fontWeight: 700,
    },
  },
});


 const generateSessionId = () => {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  };

const App = () => {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
  const [hasAgreed, setHasAgreed] = useState(false);
  const [sessionId, setSessionId] = useState(null);


  // Listen to the Firebase Auth state and set the local state.

  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // User is signed in
        const newSessionId = generateSessionId();
        setSessionId(newSessionId);

        // Update the user's document in Firestore
        const userRef = doc(db, 'profiles', user.uid);
        await setDoc(userRef, { sessionId: newSessionId }, { merge: true });

        // Listen for changes to the user's document
        const unsubscribe = onSnapshot(userRef, (doc) => {
          if (doc.exists()) {
            const data = doc.data();
            if (data.sessionId !== newSessionId) {
              // Another session has taken over
              auth.signOut();
            }
          }
        });

        setIsSignedIn(true);
        localStorage.setItem('isSignedIn', 'true');

        return () => unsubscribe();
      } else {
        // User is signed out
        setIsSignedIn(false);
        localStorage.setItem('isSignedIn', 'false');
        setSessionId(null);
      }
    });

    return () => unregisterAuthObserver();
  }, []);


  const handleChange = (event) => {
    setHasAgreed(event.target.checked);
  };

  registerLicense(
    "ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5ad0NjUHpfdHZUQGZU"
  );

  return isSignedIn ? (
    <div className="App">
      <ThemeProvider theme={theme}>
        <SubscriptionProvider>
          <Wrapper>
            <Layout db={db} auth={auth} />
          </Wrapper>
        </SubscriptionProvider>
      </ThemeProvider>
    </div>
  ) : (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundColor: "#002977",
            backgroundSize: "cover",
            backgroundPosition: "center",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",

            width: "100%",
            height: "100vh",
          }}
        >
          <X style={{ height: "20%", width: "100%", display: "flex" }} />
          <P style={{ height: "20%", width: "100%", display: "flex" }} />
          <D style={{ height: "20%", width: "100%", display: "flex" }} />
          <T style={{ height: "20%", width: "100%", display: "flex" }} />
          <R style={{ height: "20%", width: "100%", display: "flex" }} />
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={mainLogo} className={"heroClass"} alt="expediter" />
            <Typography
              component="h1"
              variant="h4"
              sx={{ textAlign: "center" }}
            >
              Welcome to XPDTR, NYC construction and real estate paperwork made
              easy!
            </Typography>
            <Typography component="p" variant="p" sx={{ marginTop: "20px" }}>
              To get started, sign in or sign up using the links below
            </Typography>
            <Typography component="p" variant="p" sx={{ marginBottom: "20px" }}>
              (registering is 100% free)
            </Typography>
           
            <Typography component="p" variant="p" sx={{ marginBottom: "20px" }}>
              <Checkbox checked={hasAgreed} onChange={handleChange} sx={{}} /> I
              agree to XPDTR.com's{" "}
              <a href="https://xpdtr.com/privacy-policy/" target="_blank">
                Privacy Policy
              </a>{" "}
              and{" "}
              <a href="https://xpdtr.com/terms-and-conditions/" target="_blank">
                Terms of Use
              </a>
            </Typography>
            <div
              style={
                hasAgreed
                  ? {}
                  : { height: "20px", pointerEvents: "none", opacity: 0.5 }
              }
            >
              <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
            </div>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default App;
