import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';


export const DeleteDialog = ({
  open,
  confirmDelete,
  setDialogOpen,
  identifier,
  loading
}) => {
  const handleClose = () => {
    setDialogOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Delete"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {`Are you sure you want to delete ${identifier}? This action cannot be undo.`}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>

     
        {
          loading ? <LoadingButton
        color="secondary"
        loading={loading}
        loadingPosition="start"
        startIcon={<SaveIcon />}
        variant="contained"
      >
        Delete..
      </LoadingButton> : <Button onClick={confirmDelete} variant="contained" color="secondary">
      Delete
    </Button> 
        }

      </DialogActions>
    </Dialog>
  );
};
