import React, { useContext, useEffect, useRef, useState } from "react";

import { Box, Link, Fab, Card, Typography } from "@mui/material/";
import Clear from "@mui/icons-material/Clear";
import Save from "@mui/icons-material/Save";

import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  Inject,
  FormFields,
  DisplayMode,
} from "@syncfusion/ej2-react-pdfviewer";

import "../styles/editorOverride.css";
import InfiniteScrollSelect from "./shared/InfiniteScrollSelect";

const PDFViewer = ({
  formToView,
  handleSave,
  handleCloseViewer,
  setLoading,
  isNewForm,
  autoFillDetails,
  isInactive,
}) => {
  const childRef = useRef();

  const [fieldArray, setFieldArray] = useState();

  // const isNewForm = true;

  const [pageNumber, setPageNumber] = useState(0);
  
  const baseUrl = `${window.location.protocol}//${window.location.host}/${process.env.PUBLIC_URL}`;




  function extractKeyValuePairs(arr) {
    const result = [];

    arr.forEach((item) => {
      if (item.details) {
        const prefix = item.prefix;
        const details = item.details;

        for (const key in details) {
          if (details.hasOwnProperty(key)) {
            const keyValue = `${prefix}${key}`;
            const value = details[key];
            result.push({ name: keyValue, value: value, type: "Textbox" });
          }
        }
      }

    });

    return result;
  }

 
  

  const onHandleSave= () => {
   
    setLoading(true);

    var viewer = childRef.current;

  
    var formFields = viewer.retrieveFormFields()
    // console.log(formFields);
    // console.log(JSON.stringify(formFields))
    // console.log(extractKeyValuePairs(formFields))
    
    viewer.exportFormFieldsAsObject().then(function (formFields) {
      viewer.exportAnnotationsAsObject().then(function (annotations) {
        console.log(formFields, annotations);
      });
    });

    /*  if (viewer.annotationCollection.length > 0) { */
    viewer.exportAnnotationsAsObject().then(function (value) {
      handleSave(JSON.stringify(formFields), value, formToView.formId);
    });
    /*   } else {
      handleSave(JSON.stringify(formFields), "", formToView.formId);
    } */
      
  };
  

  
  
  const onDocumentLoaded = () => {
    const pdfViewerInstance = childRef.current;


 

    const formContent = formToView.formContent
      ? JSON.parse(JSON.parse(formToView.formContent))
      : "";

    
    let newFieldArray = formContent
      ? formContent?.map((field) => ({
          ...field,
          filled: false,
        }))
      : null;
     
    
      listenForFormFieldChanges();
     
     if(newFieldArray==null){
       var viewer = childRef.current;
      var formFields = viewer.retrieveFormFields()
     newFieldArray= formFields.map((field) => ({
        ...field,
        filled: false,
      }))
     
     }
      // var viewer = childRef.current;
      // var formFields = viewer.retrieveFormFields()
      // const newFieldArray= formFields.map((field) => ({
      //   ...field,
      //   filled: false,
      // }))
      
      

  
    if (isNewForm || !isNewForm) {
      let autoFillFields = extractKeyValuePairs(autoFillDetails);

     
      let selectedFieldsToBeFilledAutomatically=newFieldArray.filter((field)=>{
      //  return  field.name=="appfullName" ||  field.name=="todaysDate"
       return   field.name=="todaysDate"
      })

      selectedFieldsToBeFilledAutomatically.forEach((item)=>{
        if(item.name==="todaysDate"){
          const todayDate = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
          item.value = todayDate;
        }
        pdfViewerInstance.updateFormFieldsValue(item);
      })
     

    //   autoFillFields.forEach((item) => {
    //     let fieldsToFill = document.getElementsByName(item.name);
            
    //  console.log(item.name)
    //  console.log(fieldsToFill)
    //     fieldsToFill.forEach((field) => {

    //       if (field !== undefined) {
    //         field.value = item.value;
    //         console.log(field)
    //         pdfViewerInstance.updateFormFieldsValue(field);
    //       }
    //     });
    //   });
     

    autoFillFields.forEach((item) => {
      // Replace 'owner' prefix with 'app' if it exists in the field name
      let fieldName = item.name.startsWith('owner') ? item.name.replace(/^owner/, 'app') : item.name;
  
      // Get the form fields from the pdfViewerInstance
      const formFields = pdfViewerInstance.formFieldCollections;
  
 
      const fieldsFound = formFields.filter((formField) => formField.name === fieldName);
    
      fieldsFound.forEach((field) => {
          if (field !== undefined) {

              field.value = item.value;
              pdfViewerInstance.updateFormFieldsValue(field);  // Updating the field directly
              console.log(`Updated field: ${field.name} with value: ${item.value}`);
          }
      });
  });
  
  
    
    
    }


    if (
      formToView.formAnnotations &&
      formToView.formAnnotations !== '""' &&
      formToView.formAnnotations !== ""
    ) {
      const annotations = JSON.parse(JSON.parse(formToView.formAnnotations));
      annotations && pdfViewerInstance.importAnnotation(annotations);
    }
    if (newFieldArray) {
      //  console.log(newFieldArray)
      newFieldArray.forEach((item) => {
        
        if (document.getElementById(item.id) !== null && !item.filled) {
          
          // if(item.name==="todaysDate" ){
          //   const todayDate = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
          //   item.value = todayDate;
          //   pdfViewerInstance.updateFormFieldsValue(item);
          //   item.filled = true; // Mark the field as filled
          // }

          if (item.type === "CheckBox" && item.isChecked) {
            item.value = true;
            pdfViewerInstance.updateFormFieldsValue(item);
          }
          if (item.type === "RadioButton" && item.isSelected) {
            item.value = true;
            pdfViewerInstance.updateFormFieldsValue(item);
          }

          if (item.type === "Textbox" && item.value !== "") {
            pdfViewerInstance.updateFormFieldsValue(item);
          }
          item.filled = true;
        }
      });
       
    }
   
    setFieldArray(newFieldArray);
  
   
  };



  const listenForFormFieldChanges = () => {
    const viewer = childRef.current;
  

    const formFields = viewer.retrieveFormFields();
  
    formFields?.forEach((field) => {
      const formElement = document.getElementById(field.id);
  
      if (formElement) {

        formElement.addEventListener('change', (event) => {
          handleFieldChange(event, field);
        });
  
        formElement.addEventListener('input', (event) => {
          handleFieldChange(event, field);
        });
      }
    });
  };
  
  console.log(fieldArray)
  
  const handleFieldChange = (event, field) => {
    const { value, checked, type } = event.target;
  
    // Update the field's value in your state or data structure
    setFieldArray((prevFieldArray) =>
      prevFieldArray.map((item) => {
        if (item.id === field.id) {
          return {
            ...item,
            value: type === 'checkbox' || type === 'radio' ? checked : value,
            filled: true,
          };
        }
        return item;
      })
    );
  
  };



  const onPageChange = () => {
   
  
  
    const pdfViewerInstance = childRef.current;

   
    // fieldArray.forEach((item) => {
      
    //   if (document.getElementById(item.id) !== null && !item.filled) {

       
    //     if (item.type === "CheckBox" && item.isChecked) {
    //       item.value = true;
    //       pdfViewerInstance.updateFormFieldsValue(item);
    //     }
    //     if (item.type === "RadioButton" && item.isSelected) {
    //       item.value = true;
    //       pdfViewerInstance.updateFormFieldsValue(item);
    //     }

    //     if (item.type === "Textbox" && item.value !== "") {
    //       pdfViewerInstance.updateFormFieldsValue(item);
    //     }
    //     item.filled = true;
    //   }
    // });

    const formFields = pdfViewerInstance.formFieldCollections;

    fieldArray.forEach((item) => {
      // Find the form field by ID in the formFieldCollections
      const field = formFields.find((formField) => formField.id === item.id);
    
      // Check if the field exists and if the item is not yet filled
      if (field !== undefined && !item.filled) {
        // Handle CheckBox fields
        if (item.type === "CheckBox" && item.isChecked) {
          item.value = true;
          field.value = item.value;
          pdfViewerInstance.updateFormFieldsValue(field);
        }
    
        // Handle RadioButton fields
        if (item.type === "RadioButton" && item.isSelected) {
          item.value = true;
          field.value = item.value;
          pdfViewerInstance.updateFormFieldsValue(field);
        }
    
        // Handle Textbox fields
        if (item.type === "Textbox" && item.value !== "") {
          field.value = item.value;
          pdfViewerInstance.updateFormFieldsValue(field);
        }
    
        // Mark the item as filled
        item.filled = true;
      }
    });
    
   



    if (pdfViewerInstance.currentPageNumber > pageNumber) {
      // if (fieldArray) {
      //   fieldArray.forEach((item) => {
      //     if (document.getElementById(item.id) !== null && !item.filled) {
      //       if (item.type === "CheckBox" && item.isChecked) {
      //         item.value = true;
      //         pdfViewerInstance.updateFormFieldsValue(item);
      //       }
      //       if (item.type === "RadioButton" && item.isSelected) {
      //         item.value = true;
      //         pdfViewerInstance.updateFormFieldsValue(item);
      //       }

      //       if (item.type === "Textbox" && item.value !== "") {
      //         console.log(item.value)
      //         pdfViewerInstance.updateFormFieldsValue(item);
      //       }
      //       item.filled = true;
      //     }
      //   });
      // }
      
      setPageNumber(pageNumber);
      setFieldArray(fieldArray);
    }
  
   };

 

  

  function printClicked() {
    var viewer = childRef.current;

    console.log(viewer.print);
  }

 
  const downloadEnd = () => {
     window.setTimeout(onHandleSave(), 100);
  };


//   const handleSelectChange = (newValue, valueData, prefix) => {
//  console.log(valueData)
//  console.log(prefix)
  

//     if (newValue) {
//       const pdfViewerInstance = childRef.current;
 
//       if (valueData) {
//        console.log( Object.entries(valueData))

//         Object.entries(valueData).forEach(([key, value]) => {
//           const field = document.querySelector(`input[name="${prefix}${key}"]`);

//           console.log('field , key',field , key)
//         console.log(key)
//           console.log(value)

         

//           if (field) {
//             console.log(field.id)
//             field.value = value;
//             pdfViewerInstance.updateFormFieldsValue({ id: field.id, value: value });
//           }
//         });
//       }
//     }




//   };

  const handleSelectChange = (newValue, valueData, prefix) => {
    
   
    if (newValue) {
      const pdfViewerInstance = childRef.current;
      
      
      const formFields = pdfViewerInstance.retrieveFormFields();
  
      if (valueData) {
        
        for (const field of document.querySelectorAll(`input[name^="${prefix}"]`)) {
          field.value = "";
          pdfViewerInstance.updateFormFieldsValue({
            id: field.id,
            value: '',
          });
        }
        // console.log(Object.entries(valueData));
  
        Object.entries(valueData).forEach(([key, value]) => {
        
          const matchingField = formFields.find(field => field.name === `${prefix}${key}`);
          
          console.log('Field Key:', key, 'Value:', value);
          console.log('Matching Field:', matchingField);
  
          if (matchingField) {
            // Perform the field-specific updates
            if (matchingField.name === "todaysDate") {
              const todayDate = new Date().toISOString().split('T')[0]; // Format: YYYY-MM-DD
              matchingField.value = todayDate;
              pdfViewerInstance.updateFormFieldsValue(matchingField);
            }
  
            if (matchingField.type === "CheckBox" && value) {
              matchingField.value = true; 
              pdfViewerInstance.updateFormFieldsValue(matchingField);
            }
  
            if (matchingField.type === "RadioButton" && value) {
              matchingField.value = true; 
              pdfViewerInstance.updateFormFieldsValue(matchingField);
            }
  
            if (matchingField.type === "Textbox" && value !== "") {
              matchingField.value = value; 
              pdfViewerInstance.updateFormFieldsValue(matchingField);
            }
  
            // Mark the field as filled (if necessary in your logic)
            matchingField.filled = true;
          }
        });
      }
    }
  };
  const names = {
    app: "Applicant",
    owner: "Owner",
    frep: "Filing Representative",
  };

  return (
    <div>
      <Card
        ref={(el) => {
          if (el) {
            if (
              [...document.querySelectorAll(".autoFillSelect")].filter(
                (el) => el.style.display !== "none"
              ).length === 0
            )
              el.style.display = "none";
            else el.style.display = "flex";
          }
        }}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: 2,
          mb: 2,
          p: 2,
          flexWrap: "wrap",
        }}
      >
        <Typography variant="h6" sx={{ width: "100%" }}>
          Contact Selection for Autofilling
        </Typography>
        {autoFillDetails?.map(({ prefix }) => (
          <Box
            className="autoFillSelect"
            sx={{ flex: 1 }}
            ref={(el) => {
              if (!el) return;
              if (!document.querySelector(`input[name="${prefix}lastName"]`))
                el.style.display = "none";
              else el.style.display = "block";
            }}
          >
            <InfiniteScrollSelect
              collectionName="contacts"
              label="fullName"
              inputLabel={`Select ${names?.[prefix]}`}
              onChange={(event, newValue, valueData) =>
                handleSelectChange(newValue, valueData, prefix)
              }
            />
          </Box>
        ))}
      </Card>

      <form id="pdfComponent">
        <PdfViewerComponent
          id="container"
          documentPath={window.location.origin + "/pdfs/" + formToView.fileName}
          resourceUrl="https://cdn.syncfusion.com/ej2/23.1.43/dist/ej2-pdfviewer-lib"
          enablePrint={true}
          style={{ height: "calc(100vh - 240px" }}
          signatureDialogSettings={{
            displayMode:
              DisplayMode.Draw | DisplayMode.Text | DisplayMode.Upload,
            hideSaveSignature: true,
          }}
          documentLoad={onDocumentLoaded}
          pageChange={onPageChange}
          ref={childRef}
          downloadEnd={downloadEnd}
          toolbarSettings={{
            showTooltip: true,
            toolbarItems: [
              "UndoRedoTool",
              "PageNavigationTool",
              "MagnificationTool",
              "PanTool",
              "SelectionTool",
              "CommentTool",
              "AnnotationEditTool",
              "FreeTextAnnotationOption",
              "InkAnnotationOption",
              "ShapeAnnotationOption",
              "StampAnnotation",
              "SignatureOption",
              "SearchOption",
              "PrintOption",
              "DownloadOption",
            ],
            annotationToolbarItems: [
              "HighlightTool",
              "UnderlineTool",
              "StrikethroughTool",
              "ColorEditTool",
              "OpacityEditTool",
              "AnnotationDeleteTool",
              "StampAnnotationTool",
              "HandWrittenSignatureTool",
              "InkAnnotationTool",
              "ShapeTool",
              "CalibrateTool",
              "StrokeColorEditTool",
              "ThicknessEditTool",
              "FreeTextAnnotationTool",
              "FontFamilyAnnotationTool",
              "FontSizeAnnotationTool",
              "FontStylesAnnotationTool",
              "FontAlignAnnotationTool",
              "FontColorAnnotationTool",
              "CommentPanelTool",
            ],
          }}
        >
          <Inject
            services={[
              Toolbar,
              Magnification,
              Navigation,
              Annotation,
              LinkAnnotation,
              BookmarkView,
              ThumbnailView,
              Print,
              TextSelection,
              TextSearch,
              FormFields,
            ]}
          />
        </PdfViewerComponent>
      </form>
      <Box
        sx={{
          position: "fixed",
          bottom: "1rem",
          right: "1rem",
          zIndex: 1000,
          "& > :not(style)": { m: 1 },
        }}
      >
        {!isInactive && (
          <Fab
            color="primary"
            aria-label="add"
            variant="extended"
            onClick={onHandleSave}
            sx={{ mr: 1 }}
            disabled={isInactive}
          >
            <Save sx={{ mr: 1 }} />
            Save
          </Fab>
        )}
        <Fab
          color="secondary"
          aria-label="add"
          variant="extended"
          onClick={handleCloseViewer}
        >
          <Clear sx={{ mr: 1 }} />
          Close
        </Fab>
        {/*           <Fab
            color="secondary"
            aria-label="add"
            variant="extended"
            onClick={onLogStuff}
          >
            <Clear sx={{ mr: 1 }} />
            logstuff
          </Fab> */}
      </Box>
    </div>
  );
};

export default PDFViewer;
