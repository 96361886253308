import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Clear from "@mui/icons-material/Clear";
import { DeleteDialog } from "../components/DeleteDialog";
import { Link } from "react-router-dom";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { where, or } from "firebase/firestore";
import { Context } from "context/Wrapper";
import {
  useAdvancedDataListener,
  useFetchCollectionDocs,
} from "apis/dataSnapshots";
import { db } from "config/config";
import AdvancedTable from "components/AdvancedTable";
import useCustomParams from "components/shared/useCustomParams";
import tableConfig from "config/advancedTableColumnsControl.json";

const searchLimit = tableConfig?.searchMinLength;

// const ListItem = styled(Paper)(({ theme, ...rest }) => {
//   return {
//     width: "100%",
//     padding: theme.spacing(1),
//     textAlign: "left",
//     marginTop: theme.spacing(2),
//     marginBottom: 0,
//     color: theme.palette.primary.main,
//     background: rest?.userGroupCheck
//       ? theme.palette.secondary.secondary
//       : theme.palette.secondary.main,
//   };
// });
let filterSearchedTimeout = null;

export const Projects = (props) => {
  const context = useContext(Context);
  const { updateParams, params } = useCustomParams();

   const { groupId, isPro, userId } = context?.state?.userProfile || {};
 

  const user = context?.state?.userProfile || {},
    { showAllDataFlag } = context?.state || {};

  const contacts = useFetchCollectionDocs({
    collectionName: "contacts",
    conditions:
      userId && groupId && isPro && showAllDataFlag
        ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
        : userId
          ? [where("userId", "==", userId)]
          : [],
    setContext: context.setState,
  });

  const buildings = useFetchCollectionDocs({
    collectionName: "buildings",
    conditions:
      userId && groupId && isPro && showAllDataFlag
        ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
        : userId
          ? [where("userId", "==", userId)]
          : [],
  });

  const groups = useFetchCollectionDocs({
    collectionName: "groups",
    conditions: [true],
    // userId && groupId && isPro && showAllDataFlag
    //   ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
    //   : userId
    //     ? [where("userId", "==", userId)]
    //     : [],
  });

  // const { db, user, isPro, allData, groupId } = props;
  const [projects, setProjects] = useState([]);

  const navigate = useNavigate();

  const [deleteOpen, setDeleteOpen] = useState(false);

  const [itemToDelete, setItemToDelete] = useState({
    itemId: "",
    identifier: "",
  });

  const [filteredProjects, setFilteredProjects] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const { data, count } = useAdvancedDataListener({
    user,
    // conditions,
    // setLastRef: setLastVisible,
    condition: groupId && isPro && showAllDataFlag,
    collectionName: "projects",
    // rowsLimit,
    // params,
  });

  useEffect(() => {
    updateParams({
      page: "",
      search: "",
      pageIncreasing: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newprojects = data.map((data) => {
      const client = contacts.find((contact) => contact.id === data.client);
      const tenant = contacts.find((contact) => contact.id === data.applicant);
      const filingRep = contacts.find(
        (contact) => contact.id === data.filingRep
      );
      const projectManager = contacts.find(
        (contact) => contact.id === data.projectManager
      );
      const group = groups.find((group) => group.id === data.groupId);

      const applicant = contacts.find(
        (contact) => contact.id === data.applicant
      );

      const building = buildings.find(
        (building) => building.id === data.building
      );

      return {
        ...data,
        clientString: client?.businessName || "",
        tenantString: tenant?.businessName || "",
        applicantString: applicant?.fullName || "",
        buildingString: building?.customerReference || "",
        buildingAddress:
          (building?.houseNumber || "") + " " + (building?.streetName || ""),
        groupName: group?.groupName || "",
        projectManagerName:
          projectManager?.businessName || projectManager?.fullName || "",
        filingRepName: filingRep?.businessName || filingRep?.fullName || "",
      };
    });

    setProjects(newprojects);
  }, [buildings, contacts, data]);

  useEffect(() => {
    if (
      (searchValue?.length < searchLimit && searchValue?.length !== 0) ||
      !searchValue
    ) {
      if (searchValue?.length === 0) {
        updateParams({ search: "", pageSize: params.get("pageSize") });
      }
      return;
    }
    if (filterSearchedTimeout !== null) clearTimeout(filterSearchedTimeout);
    filterSearchedTimeout = setTimeout(() => {
      updateParams({ search: searchValue, pageSize: params.get("pageSize") });

      const newArr = projects
        .filter(
          (item) =>
            (item.customerReference &&
              item.customerReference
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (item.clientString &&
              item.clientString
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (item.applicantString &&
              item.applicantString
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (item.buildingString &&
              item.buildingString
                .toLowerCase()
                .includes(searchValue.toLowerCase())) ||
            (item.DOBNumber &&
              item.DOBNumber.toLowerCase().includes(searchValue.toLowerCase()))
        )
        .map((item) => {
          let newCustomerReference =
            item.customerReference &&
            item.customerReference.replace(
              new RegExp(searchValue, "gi"),
              (match) =>
                `<mark style="background: #2769AA; color: white;">${match}</mark>`
            );
          let newClientString =
            item.clientString &&
            item.clientString.replace(
              new RegExp(searchValue, "gi"),
              (match) =>
                `<mark style="background: #2769AA; color: white;">${match}</mark>`
            );
          let newApplicantString =
            item.applicantString &&
            item.applicantString.replace(
              new RegExp(searchValue, "gi"),
              (match) =>
                `<mark style="background: #2769AA; color: white;">${match}</mark>`
            );
          let newBuildingString =
            item.buildingString &&
            item.buildingString.replace(
              new RegExp(searchValue, "gi"),
              (match) =>
                `<mark style="background: #2769AA; color: white;">${match}</mark>`
            );
          let newDOBNumber =
            item.DOBNumber &&
            item.DOBNumber.replace(
              new RegExp(searchValue, "gi"),
              (match) =>
                `<mark style="background: #2769AA; color: white;">${match}</mark>`
            );

          return {
            ...item,
            customerReference: newCustomerReference,
            clientString: newClientString,
            applicantString: newApplicantString,
            buildingString: newBuildingString,
            DOBNumber: newDOBNumber,
          };
        });

      setFilteredProjects(newArr);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projects, searchValue]);

  // useEffect(() => {
  //   if (!user?.groupId) return;
  //   console.log("lastVisible", lastVisible);
  //   console.count("lastVisible");

  // const unsubscribe = listenAndFilterTo({
  //   setData: (docsData) => {
  //     const newprojects = docsData.map((data) => {
  //       const client = contacts.find((contact) => contact.id === data.client);
  //       const tenant = contacts.find(
  //         (contact) => contact.id === data.applicant
  //       );

  //       const applicant = contacts.find(
  //         (contact) => contact.id === data.applicant
  //       );

  //       const building = buildings.find(
  //         (building) => building.id === data.building
  //       );

  //       return {
  //         ...data,
  //         clientString: client?.businessName || "",
  //         tenantString: tenant?.businessName || "",
  //         applicantString: applicant?.fullName || "",
  //         buildingString: building?.customerReference || "",
  //         buildingAddress:
  //           (building?.houseNumber || "") +
  //           " " +
  //           (building?.streetName || ""),
  //       };
  //     });

  //     // setLastVisible(docsData?.[docsData.length - 1]);

  //     setProjects(newprojects);
  //   },
  //   user,
  //   setContext: context?.setState,
  //   condition: groupId && isPro && showAllDataFlag,
  //   collectionName: "projects",
  //   conditions,
  //   setLastRef: setLastVisible,
  // });

  // return () => unsubscribe();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   isPro,
  //   groupId,
  //   showAllDataFlag,
  //   JSON.stringify(user),
  //   contacts,
  //   buildings,
  //   `${params}`,
  // ]);

  // }, [props.buildings, props.contacts]);

  const handleSearch = (e) => {
    const value = e.target.value;
    // if (searchTimeout !== null) clearTimeout(searchTimeout);
    setSearchValue(value);
    // console.log('value',value)
    // searchTimeout = setTimeout(() => {
    //   updateParams({ search: value });
    // }, 500);
  };
  // const handleInput = (e) => {
  //   let str = e.target.value;

  //   setSearchValue(str);
  // };

  const handleDelete = (id, identifier) => {
    setItemToDelete({ itemId: id, identifier: identifier });
    setDeleteOpen(true);
  };

  const confirmDelete = async () => {
    db.collection("projects").doc(itemToDelete.itemId).delete();
    setItemToDelete({ itemId: "", identifier: "" });
    setDeleteOpen(false);
    navigate("/projects");
  };
  const searchCheck = searchValue.length >= searchLimit;
  const projectCollection = searchCheck ? filteredProjects : projects;

  return (
    <>
      <Fade in={true}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={8} style={{ position: "sticky" }}>
              <Typography align="left" variant="h4">
                JOBS
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Paper
                component="form"
                onSubmit={(e) => e.preventDefault()}
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginLeft: "auto",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  value={searchValue}
                  inputProps={{ "aria-label": "search" }}
                  onInput={handleSearch}
                />
                {searchCheck ? (
                  <IconButton
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={() => {
                    setSearchValue("");
                    updateParams({ search: "" });
                  }}
                >
                    <Clear />
                  </IconButton>
                ) : (
                  <IconButton sx={{ p: "10px" }} aria-label="clear">
                    <SearchIcon />
                  </IconButton>
                )}
              </Paper>
            </Grid>
          </Grid>
          <AdvancedTable
            data={projectCollection}
            collectionName="projects"
            columnsRaw={[
              // {
              //   field: "id",
              //   headerName: "Job ID",
              // },
              {
                field: "customerReference",
                headerName: "Job ID",
                renderCell: ({ row }) => (
                  <div
                    dangerouslySetInnerHTML={{ __html: row.customerReference }}
                  ></div>
                ),
              },
              {
                field: "buildingString",
                headerName: "Building",
                renderCell: ({ row }) => (
                  <div
                    dangerouslySetInnerHTML={{ __html: row.buildingString }}
                  ></div>
                ),
              },
              {
                field: "buildingAddress",
                headerName: "Address",
              },
              {
                field: "clientString",
                headerName: "Client",
                renderCell: ({ row }) => (
                  <div
                    dangerouslySetInnerHTML={{ __html: row.clientString }}
                  ></div>
                ),
              },
              {
                field: "tenantString",
                headerName: "Tenant",
              },
              {
                field: "DOBNumber",
                headerName: "DOB Number",
                renderCell: ({ row }) => (
                  <div
                    dangerouslySetInnerHTML={{ __html: row.DOBNumber }}
                  ></div>
                ),
              },
              {
                field: "applicant",
                headerName: "Applicant ID",
              },
              {
                field: "applicantString",
                headerName: "Applicant Name",
                renderCell: ({ row }) => (
                  <div
                    dangerouslySetInnerHTML={{ __html: row.applicantString }}
                  ></div>
                ),
              },
              {
                field: "building",
                headerName: "Building ID",
              },
              {
                field: "client",
                headerName: "Client ID",
              },
              {
                field: "filingRepName",
                headerName: "Filing Rep",
              },
              {
                field: "groupName",
                headerName: "Group",
              },
              {
                field: "projectManagerName",
                headerName: "Project Manager",
              },
            ]}
            rowCount={searchCheck ? projectCollection?.length : count}
            // rowsLimit={rowsLimit}
            handleDelete={handleDelete}
          />

          <Box
            sx={{
              position: "fixed",
              bottom: "1rem",
              left: "3.5rem",
              "& > :not(style)": { m: 1 },
            }}
          >
            {!props.isInactive && (
              <Link to="/projects/new">
                <Fab
                  color="primary"
                  aria-label="add"
                  variant="extended"
                  disabled={props.isInactive}
                >
                  <AddIcon sx={{ mr: 1 }} />
                  New
                </Fab>
              </Link>
            )}
          </Box>
        </div>
      </Fade>
      <DeleteDialog
        open={deleteOpen}
        confirmDelete={confirmDelete}
        setDialogOpen={setDeleteOpen}
        identifier={itemToDelete.identifier}
      />
    </>
  );
};
