import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  styled,
  TextField,
  Paper,
  Grid,
  Box,
  Tab,
  Tabs,
  Fab,
  MenuItem,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";

import ReactHookFormSelect from "../components/ReactHookFormSelect";
import { SaveCloseDialog } from "../components/SaveCloseDialog";
import { Add, Save, Close } from "@mui/icons-material";
import { useForm, Controller, useFormState } from "react-hook-form";
import PersonStatus from "./PersonStatus";
import Notes from "components/Notes";

import { doc, getDoc, setDoc } from "firebase/firestore";
import { db } from "config/config";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => {
  return {
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
  };
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const ContactDetail = (props) => {
  const { contactId } = useParams();

  const navigate = useNavigate();

  const [contactDetail, setContactDetail] = useState();

  useEffect(() => {
    async function getContacts() {
      const contactRef = doc(db, "contacts", contactId);
      const initialContactDetail = await getDoc(contactRef);
      setContactDetail(initialContactDetail.data());
    }

    getContacts();
  }, [contactId]);

  const { handleSubmit, control, reset } = useForm();

  const [tab, setTab] = React.useState(0);

  const [open, setOpen] = React.useState(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [failureOpen, setOpenFailure] = React.useState(false);

  const openSuccess = () => {
    setOpen(true);
  };

  const openFailure = () => {
    setOpenFailure(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleFailureClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailure(false);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleChange = (e) => {
    let currentContactDetail = contactDetail;
    currentContactDetail[e.target.name] = e.target.value;
    setIsDirty(true);
    setContactDetail({ ...currentContactDetail });
  };

  const handleChangeNotes = (notes) => {
    let currentContactDetail = contactDetail;
    setContactDetail({ ...currentContactDetail, notes: notes });
  };

  const onSubmit = () => {
    const thisContact = doc(db, "contacts", contactId);
    setDoc(thisContact, { ...contactDetail }, { merge: true })
      .then(() => {
        setIsDirty(false);
        openSuccess();
      })
      .catch((error) => {
        openFailure();
      });
  };

  const saveAndClose = () => {
    handleSubmit(onSubmit)();
    navigate("/Contacts");
  };

  const discardAndClose = () => {
    navigate("/Contacts");
  };

  const [isDirty, setIsDirty] = React.useState(false);

  const handleCloseContact = () => {
    if (isDirty) {
      setDialogOpen(true);
      return;
    }
    navigate("/contacts");
  };

  const hasRequiredFields = () => {
    return !!contactDetail.fullName;
  };

  if (!contactDetail) return null;

  return (
    <>
      <Typography sx={{ textTransform: "uppercase" }} align="left" variant="h4">
        {contactDetail.fullName}
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider", paddingLeft: 0 }}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label="Details" />
          <Tab label="DOB status" />
          <Tab label="Notes" />
          {/*  <Tab label="Applications" /> */}
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <form name="contactForm" id="contactForm">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label={"Salutation"}
                name="salutation"
                select
                onChange={handleChange}
                value={contactDetail.borough}
                fullWidth
              >
                <MenuItem value={"Mr"}>Mr</MenuItem>
                <MenuItem value={"Mrs"}>Mrs</MenuItem>
                <MenuItem value={"Ms"}>Ms</MenuItem>
                <MenuItem value={"Miss"}>Miss</MenuItem>
                <MenuItem value={"Mx"}>Mx</MenuItem>
                <MenuItem value={"Dr"}>Dr.</MenuItem>
                <MenuItem value={"Rev"}>Rev</MenuItem>
                <MenuItem value={"Rabbi"}>Rabbi</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Full Name"
                name="fullName"
                variant="outlined"
                value={contactDetail.fullName}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="First name"
                name="firstName"
                variant="outlined"
                value={contactDetail.firstName}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="MI"
                name="MI"
                variant="outlined"
                value={contactDetail.MI}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Last name"
                name="lastName"
                variant="outlined"
                value={contactDetail.lastName}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "2rem" }} spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Business name"
                name="businessName"
                variant="outlined"
                value={contactDetail.businessName}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Address 1"
                name="address1"
                variant="outlined"
                value={contactDetail.address1}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Address 2"
                name="address2"
                variant="outlined"
                value={contactDetail.address2}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="City"
                name="city"
                variant="outlined"
                value={contactDetail.city}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="State"
                name="state"
                variant="outlined"
                value={contactDetail.state}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="ZIP"
                name="ZIP"
                variant="outlined"
                value={contactDetail.ZIP}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "2rem" }} spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Phone"
                name="phone"
                variant="outlined"
                value={contactDetail.phone}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Cell"
                name="mobile"
                variant="outlined"
                value={contactDetail.mobile}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Fax"
                name="fax"
                variant="outlined"
                value={contactDetail.fax}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Email"
                name="email"
                variant="outlined"
                value={contactDetail.email}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Website"
                name="website"
                variant="outlined"
                value={contactDetail.website}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: "2rem" }} spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="License type"
                name="licenseType"
                variant="outlined"
                select
                value={contactDetail.licenseType}
                onChange={handleChange}
                fullWidth
              >
                <MenuItem value={"RA"}>RA</MenuItem>
                <MenuItem value={"PE"}>PE</MenuItem>
                <MenuItem value={"Filing rep"}>Filing rep</MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="License number"
                name="licenseNumber"
                variant="outlined"
                value={contactDetail.licenseNumber}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Filing rep registration number"
                name="frepRegistrationNumber"
                variant="outlined"
                value={contactDetail.frepRegistrationNumber}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Tax id"
                name="taxId"
                variant="outlined"
                value={contactDetail.taxId}
                onChange={handleChange}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="HIC number"
                name="HICNumber"
                variant="outlined"
                value={contactDetail.HICNumber}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Insurance company"
                name="insuranceCompany"
                variant="outlined"
                value={contactDetail.insuranceCompany}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Policy number"
                name="policyNumber"
                variant="outlined"
                value={contactDetail.policyNumber}
                onChange={handleChange}
                fullWidth
              />
            </Grid>
          </Grid>
        </form>
        <Box
          sx={{
            position: "fixed",
            bottom: "1rem",
            right: "1rem",
            "& > :not(style)": { m: 1 },
          }}
        >
          {!props.isInactive && (
            <Fab
              color="primary"
              aria-label="add"
              variant="extended"
              onClick={onSubmit}
              form="contactForm"
              disabled={(!isDirty && !hasRequiredFields()) || props.isInactive}
            >
              <Save sx={{ mr: 1 }} />
              Save
            </Fab>
          )}
          <Fab
            color="secondary"
            aria-label="add"
            variant="extended"
            onClick={handleCloseContact}
          >
            <Close sx={{ mr: 1 }} />
            Close
          </Fab>
        </Box>
        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Contact successfully saved!
          </Alert>
        </Snackbar>
        <Snackbar
          open={failureOpen}
          autoHideDuration={2000}
          onClose={handleFailureClose}
        >
          <Alert
            onClose={handleFailureClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            Something went wrong!
          </Alert>
        </Snackbar>
        <SaveCloseDialog
          open={dialogOpen}
          saveAndClose={saveAndClose}
          discardAndClose={discardAndClose}
          setDialogOpen={setDialogOpen}
          isInactive={props.isInactive}
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        {!props.isInactive ? (
          <div style={{ height: "calc(100vh - 230px)" }}>
            <PersonStatus currentPerson={{ ...contactDetail }} />
          </div>
        ) : (
          <div style={{ height: "calc(100vh - 230px)" }}>
            This feature is available with an active subscription.
          </div>
        )}
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Notes
          notes={contactDetail.notes}
          handleChangeNotes={handleChangeNotes}
          onSubmit={onSubmit}
        />
      </TabPanel>
      {/*  <TabPanel value={tab} index={2}>
        Forms
      </TabPanel> */}
    </>
  );
};
