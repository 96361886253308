import { forwardRef, useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  styled,
  TextField,
  Paper,
  Grid,
  Box,
  Tab,
  Tabs,
  Fab,
  MenuItem,
  Snackbar,
  ListItem,
  Button,
  CircularProgress,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import DatePicker from "@mui/lab/DatePicker";

import { SaveCloseDialog } from "../components/SaveCloseDialog";
import { Add, Save, Close } from "@mui/icons-material";
import { useForm } from "react-hook-form";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  runTransaction,
  setDoc,
  where,
} from "firebase/firestore";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import { db } from "config/config";
import { Context } from "context/Wrapper";
import { getDocData } from "apis/documents";
import InfiniteScrollSelect from "components/shared/InfiniteScrollSelect";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => {
  return {
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
  };
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const ProfileDetail = (props) => {
  const { contactId } = useParams();

  const context = useContext(Context);

  const user = context?.state?.userProfile || {};

  const navigate = useNavigate();

  const [contactDetail, setContactDetail] = useState();
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    async function getContacts() {
      const contactRef = doc(db, "profiles", contactId);
      const initialContactDetail = await getDoc(contactRef);
      setContactDetail({
        ...initialContactDetail.data(),
        id: initialContactDetail.id,
      });
    }

    getContacts();

    getGroups();
  }, [contactId]);

  const { handleSubmit, control, reset } = useForm();

  const [tab, setTab] = useState(0);

  const [open, setOpen] = useState(false);

  const [dialogOpen, setDialogOpen] = useState(false);

  const [failureOpen, setOpenFailure] = useState(false);

  const getGroups = async (groupId) => {
    const data = await getDocs(query(collection(db, "groups")));
    if (!data.empty)
      setGroups(
        data?.docs?.map((doc) => ({
          ...doc?.data(),
          id: doc.id,
        }))
      );
  };

  const openSuccess = () => {
    setOpen(true);
  };

  const openFailure = () => {
    setOpenFailure(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleFailureClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailure(false);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleChange = (e) => {
    let currentContactDetail = contactDetail;
    currentContactDetail[e.target.name] = e.target.value;
    setIsDirty(true);
    setContactDetail({ ...currentContactDetail });
  };

  const handleDateChange = (fieldName, value) => {
    let currentContactDetail = contactDetail;
    setIsDirty(true);
    currentContactDetail[fieldName] = JSON.stringify(value);
    setContactDetail({ ...currentContactDetail });
  };

  const onSubmit = () => {
    const thisContact = doc(db, "profiles", contactId);

    const userProjectsRef = query(
      collection(db, "projects"),
      where("userId", "==", contactDetail.id)
    );
    const userBuildingsRef = query(
      collection(db, "buildings"),
      where("userId", "==", contactDetail.id)
    );
    const userContactsRef = query(
      collection(db, "contacts"),
      where("userId", "==", contactDetail.id)
    );
    const userDocTypesRef = query(
      collection(db, "documentTypes"),
      where("userId", "==", contactDetail.id)
    );
    const userTodosRef = query(
      collection(db, "todos"),
      where("userId", "==", contactDetail.id)
    );
    const userFilingsRef = query(
      collection(db, "filings"),
      where("userId", "==", contactDetail.id)
    );
    context?.setState({
      snackbar: (
        <CircularProgress
          size={46}
          style={{
            position: "fixed",
            right: "10%",
            bottom: "15%",
            zIndex: 9,
          }}
        />
      ),
    });
    runTransaction(db, async (transaction) => {
      const userProjectsSnapshot = await getDocs(userProjectsRef);
      const userBuildingsSnapshot = await getDocs(userBuildingsRef);
      const userContactsSnapshot = await getDocs(userContactsRef);
      const userDocTypesSnapshot = await getDocs(userDocTypesRef);
      const userTodosSnapshot = await getDocs(userTodosRef);
      const userFilingsSnapshot = await getDocs(userFilingsRef);

      if (!userProjectsSnapshot?.empty)
        userProjectsSnapshot.forEach((doc) => {
          transaction.update(doc.ref, { groupId: contactDetail?.groupId });
        });
      if (!userBuildingsSnapshot?.empty)
        userBuildingsSnapshot.docs.forEach((doc) => {
          transaction.update(doc.ref, { groupId: contactDetail?.groupId });
        });
      if (!userContactsSnapshot?.empty)
        userContactsSnapshot.docs.forEach((doc) => {
          transaction.update(doc.ref, { groupId: contactDetail?.groupId });
        });
      if (!userDocTypesSnapshot?.empty)
        userDocTypesSnapshot.docs.forEach((doc) => {
          transaction.update(doc.ref, { groupId: contactDetail?.groupId });
        });
      if (!userTodosSnapshot?.empty)
        userTodosSnapshot.docs.forEach((doc) => {
          transaction.update(doc.ref, { groupId: contactDetail?.groupId });
        });
      if (!userFilingsSnapshot?.empty)
        userFilingsSnapshot.docs.forEach((doc) => {
          transaction.update(doc.ref, { groupId: contactDetail?.groupId });
        });

      transaction.update(thisContact, {
        ...contactDetail,
      });
    })
      .then(() => {
        setIsDirty(false);
        openSuccess();
        setTimeout(() => {
          context?.setState({ snackbar: null });
        }, 1000);
      })
      .catch((error) => {
        console.log("error", error);
        openFailure();
        setTimeout(() => {
          context?.setState({ snackbar: null });
        }, 1000);
      });

    // setDoc(thisContact, { ...contactDetail }, { merge: true })
    //   .then(() => {
    //     setIsDirty(false);
    //     openSuccess();
    //   })
    //   .catch((error) => {
    //     openFailure();
    //   });
  };

  const saveAndClose = () => {
    handleSubmit(onSubmit)();
    navigate("/Profiles");
  };

  const discardAndClose = () => {
    navigate("/Profiles");
  };

  const [isDirty, setIsDirty] = useState(false);

  const handleCloseContact = () => {
    if (isDirty) {
      setDialogOpen(true);
      return;
    }
    navigate("/profiles");
  };

  const hasRequiredFields = () => {
    return !!contactDetail.subscription && !!contactDetail.subscriptionType;
  };

  if (!contactDetail) return null;

  if (
    user.uid !== process.env.REACT_APP_DEV_FIREBASE_ADMIN_ID &&
    user.uid !== process.env.REACT_APP_PROD_FIREBASE_ADMIN_ID &&
    user.uid !== process.env.REACT_APP_PROD_FIREBASE_ADMIN_ID_ALT &&
    user.uid !== process.env.REACT_APP_DEV_FIREBASE_ADMIN_ID_ALT &&
    user.uid !== process.env.REACT_APP_DEV_FIREBASE_ADMIN_ID_ALT2 &&
    user.uid !== process.env.REACT_APP_DEV_FIREBASE_ADMIN_ID_ALT3 &&
    user.uid !== process.env.REACT_APP_DEV_FIREBASE_ADMIN_ID_ALT4

  ) {
    return "You are not authorized to view this page...............";
  }

  return (
    <>
      <Typography sx={{ textTransform: "uppercase" }} align="left" variant="h4">
        {contactDetail.email}
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider", paddingLeft: 0 }}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="basic tabs example"
        >
          <Tab label="Details" />
          {/*  <Tab label="Applications" /> */}
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <form name="contactForm" id="contactForm">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Email"
                name="email"
                variant="outlined"
                value={contactDetail.email}
                onChange={handleChange}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label={"Subscription"}
                name="subscription"
                select
                onChange={handleChange}
                value={contactDetail.subscription}
                fullWidth
                required
              >
                <MenuItem value={"trial"}>Trial</MenuItem>
                <MenuItem value={"active"}>Active</MenuItem>
                <MenuItem value={"inactive"}>Inactive</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label={"Subscription Type"}
                name="subscriptionType"
                select
                onChange={handleChange}
                value={contactDetail.subscriptionType}
                fullWidth
                required
              >
                <MenuItem value={"standard"}>Standard</MenuItem>
                <MenuItem value={"pro"}>Professional</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              {/* <InfiniteScrollSelect
                collectionName="groups"
                label="groupName"
                onChange={handleChange}
                value={contactDetail.building}
                name="groupId"
                inputLabel="Group ID"
                conditionsFlag={false}
              /> */}
              <TextField
                label="Group ID"
                name="groupId"
                select
                onChange={handleChange}
                value={contactDetail.groupId}
                fullWidth
                required
              >
                {(groups || [])?.map((item) => (
                  <MenuItem value={item?.id}>{item?.groupName}</MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12} md={6} lg={3}>
              <TextField
                label="Expiration date"
                name="expirationDate"
                variant="outlined"
                value={contactDetail.expirationDate}
                onChange={handleChange}
                fullWidth
                required
                type="date"
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={6} lg={3}>
                <DatePicker
                  label="Subscription Expires"
                  value={contactDetail.expirationDate}
                  onChange={(newValue) =>
                    handleDateChange("expirationDate", newValue)
                  }
                  fullWidth
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </form>
        <Box
          sx={{
            position: "fixed",
            bottom: "1rem",
            right: "1rem",
            "& > :not(style)": { m: 1 },
          }}
        >
          {!props.isInactive && (
            <Fab
              color="primary"
              aria-label="add"
              variant="extended"
              onClick={onSubmit}
              form="contactForm"
              disabled={(!isDirty && !hasRequiredFields()) || props.isInactive}
            >
              <Save sx={{ mr: 1 }} />
              Save
            </Fab>
          )}
          <Fab
            color="secondary"
            aria-label="add"
            variant="extended"
            onClick={handleCloseContact}
          >
            <Close sx={{ mr: 1 }} />
            Close
          </Fab>
        </Box>
        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Contact successfully saved!
          </Alert>
        </Snackbar>
        <Snackbar
          open={failureOpen}
          autoHideDuration={2000}
          onClose={handleFailureClose}
        >
          <Alert
            onClose={handleFailureClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            Something went wrong!
          </Alert>
        </Snackbar>
        <SaveCloseDialog
          open={dialogOpen}
          saveAndClose={saveAndClose}
          discardAndClose={discardAndClose}
          setDialogOpen={setDialogOpen}
          isInactive={props.isInactive}
        />
      </TabPanel>
      {/*  <TabPanel value={tab} index={2}>
        Forms
      </TabPanel> */}
    </>
  );
};
