import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  styled,
  TextField,
  Paper,
  Grid,
  Box,
  Tab,
  Tabs,
  Fab,
  MenuItem,
  Snackbar,
  Select,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import { SaveCloseDialog } from "../components/SaveCloseDialog";
import { Add, Save, Close } from "@mui/icons-material";
import { doc, getDoc, or, setDoc, where } from "firebase/firestore";
import { WorkTypes } from "../components/WorkTypes";

import ProjectStatus from "./ProjectStatus";
import { Forms } from "../components/Forms";
import Notes from "../components/Notes";
import Documents from "../components/Documents";
import { Context } from "context/Wrapper";
import { db } from "config/config";
import { useFetchCollectionDocs } from "apis/dataSnapshots";
import InfiniteScrollSelect from "components/shared/InfiniteScrollSelect";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => {
  return {
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
  };
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export const ProjectDetail = (props) => {
  // const groupId = props.groupId;

  const context = useContext(Context);

const { groupId, isPro, userId } = context?.state?.userProfile || {};
 
  
  const user = context?.state?.userProfile || {},

    { showAllDataFlag } = context?.state || {};

  const contacts = useFetchCollectionDocs({
    collectionName: "contacts",
    conditions:
      userId && groupId && isPro && showAllDataFlag
        ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
        : userId
          ? [where("userId", "==", userId)]
          : [],
  });


  const buildings = useFetchCollectionDocs({
    collectionName: "buildings",
    conditions:
      userId && groupId && isPro && showAllDataFlag
        ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
        : userId
          ? [where("userId", "==", userId)]
          : [],
  });

  

  const { projectId } = useParams();

  const navigate = useNavigate();

  const [projectDetail, setProjectDetail] = useState();

  const [ownerDetails, setOwnerDetails] = React.useState(null);

  // const [applicantDetails, setApplicantDetails] = React.useState(null);

  // const [filingRepDetails, setFilingRepDetails] = React.useState(null);

  // const [buildingDetails, setBuildingDetails] = React.useState(null);


  const getProject = async () => {
    const projectRef = doc(db, "projects", projectId);
    const initialProjectDetail = await getDoc(projectRef);
    return setProjectDetail({
      ...initialProjectDetail.data(),
      id: initialProjectDetail.id,
    });
  };
  const [workTypes, setWorkTypes] = React.useState([]);

  useEffect(() => {
       getProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  let applicantDetails = contacts?.find(
    (contact) => projectDetail?.applicant === contact?.id,
  );
  let filingRepDetails = contacts?.find(
    (contact) => projectDetail?.filingRep === contact?.id,
  );
  //const buildingDetails = buildings?.find(
  //   (building) => building?.filingRep === building?.id,
  // );

  let buildingDetails = buildings?.find(
    (building) => projectDetail?.building === building?.id,
  );
console.log(buildingDetails)
console.log(filingRepDetails)
 

  useEffect(() => {
    const unsubscribe = db
      .collection("workTypes")
      .where("projectId", "==", projectId)
      .onSnapshot((snapshot) => {
        const workTypesData = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setWorkTypes(workTypesData);
      });
    return () => unsubscribe();
  }, [projectId]);

  const [tab, setTab] = React.useState(0);

  const [open, setOpen] = React.useState(false);

  const [isDirty, setIsDirty] = React.useState(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [failureOpen, setOpenFailure] = React.useState(false);

  const openSuccess = () => {
    setOpen(true);
  };

  const openFailure = () => {
    setOpenFailure(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleFailureClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailure(false);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const onSubmit = () => {
    const thisProject = doc(db, "projects", projectId);
    setDoc(thisProject, { ...projectDetail }, { merge: true })
      .then(() => {
        setIsDirty(false);
        openSuccess();
      })
      .catch((error) => {
        openFailure();
      });
  };

  const handleDateChange = (fieldName, value) => {
    let currentProjectDetail = projectDetail;
    setIsDirty(true);
    currentProjectDetail[fieldName] = JSON.stringify(value);
    setProjectDetail({ ...currentProjectDetail });
  };

  const saveAndClose = () => {
    onSubmit(projectDetail);
    navigate("/projects");
  };

  const handleChange = (e) => {
    let currentProjectDetail = projectDetail;
    currentProjectDetail[e.target.name] = e.target.value;
    setIsDirty(true);
    setProjectDetail({ ...currentProjectDetail });
  };

  const discardAndClose = () => {
    navigate("/projects");
  };

  const handleChangeNotes = (notes) => {
    let currentProjectDetail = projectDetail;

    setProjectDetail({ ...currentProjectDetail, notes: notes });
  };

  const handleChangeDocuments = (data) => {
    setProjectDetail((prevVal) => ({ ...prevVal, documents: data }));
  };

  const handleCloseProject = () => {
    if (isDirty) {
      setDialogOpen(true);
      return;
    }
    navigate("/projects");
  };

  // React.useEffect(() => {
  //   if (projectDetail) {
  //     const fetchApplicantDetails = async () => {
  //       const applicantRef = doc(db, "contacts", projectDetail?.applicant);
  //       const initialApplicantDetail = await getDoc(applicantRef);
  //       const applicant = initialApplicantDetail.data();
  //       setApplicantDetails(applicant);
  //     };
  //     fetchApplicantDetails();
  //   }
  // }, [projectDetail, projectDetail?.applicant]);

  // React.useEffect(() => {
  //   if (projectDetail) {
  //     const fetchFilingRepDetails = async () => {
  //       const filingRepRef = doc(db, "contacts", projectDetail?.filingRep);
  //       const initialFilingRepDetail = await getDoc(filingRepRef);
  //       const filingRep = initialFilingRepDetail.data();
  //       setFilingRepDetails(filingRep);
  //     };
  //     fetchFilingRepDetails();
  //   }
  // }, [projectDetail, projectDetail?.filingRep]);

  // React.useEffect(() => {
  //   if (projectDetail) {
  //     const fetchBuildingDetails = async () => {
  //       const buildingRef = doc(db, "buildings", projectDetail?.building);
  //       const initialBuildingDetail = await getDoc(buildingRef);
  //       const building = initialBuildingDetail.data();
  //       setBuildingDetails(building);
  //     };
  //     fetchBuildingDetails();
  //   }
  // }, [projectDetail]);

  React.useEffect(() => {
    if (buildingDetails) {
      const fetchOwnerDetails = async () => {
        const ownerRef = doc(db, "contacts", buildingDetails?.owner);
        const initialOwnerDetail = await getDoc(ownerRef);
        const owner = initialOwnerDetail.data();
        setOwnerDetails(owner);
      };
      fetchOwnerDetails();
    }
  }, [buildingDetails]);

  const hasRequiredFields = () => {
    return !!projectDetail?.customerReference;
  };

  if (!projectDetail) return null;

  return (
    <>
      <Typography sx={{ textTransform: "uppercase" }} align="left" variant="h4">
        {projectDetail.customerReference}
      </Typography>
      <Box sx={{ borderBottom: 1, borderColor: "divider", paddingLeft: 0 }}>
        <Tabs
          value={tab}
          onChange={handleChangeTab}
          aria-label="tab navigation"
        >
          <Tab label="Details" />
          <Tab label="Work Types" />
          <Tab label="Documents" />
          <Tab label="DOB status" />
          <Tab label="Notes" />
          <Tab label="Forms" />
        </Tabs>
      </Box>
      <TabPanel value={tab} index={0}>
        <form name="projectForm" id="projectForm">
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                label="Job ID"
                variant="outlined"
                name="customerReference"
                value={projectDetail.customerReference}
                onInput={handleChange}
                fullWidth
                required
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/* <TextField
                label={"Building"}
                name="building"
                select
                onChange={handleChange}
                value={projectDetail.building}
                fullWidth
              >
                {buildings.map((building) => (
                  <MenuItem value={building.id}>
                    {building.customerReference}
                  </MenuItem>
                ))}
              </TextField> */}
              <InfiniteScrollSelect
                collectionName="buildings"
                label="customerReference"
                onChange={handleChange}
                value={projectDetail.building}
                name="building"
                inputLabel="Building"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <TextField
                name="DOBNumber"
                label="DOB reference (BIS or Now!)"
                variant="outlined"
                value={projectDetail.DOBNumber}
                onInput={handleChange}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/* <TextField
                label={"Client"}
                name="client"
                onChange={handleChange}
                value={projectDetail.client}
                select
                fullWidth
              >
                {contacts.map((contact) => (
                  <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
                ))}
              </TextField> */}
              <InfiniteScrollSelect
                collectionName="contacts"
                label="fullName"
                onChange={handleChange}
                value={projectDetail.client}
                name="client"
                inputLabel="Client"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/* <TextField
                label={"Applicant"}
                name="applicant"
                onChange={handleChange}
                select
                value={projectDetail.applicant}
                fullWidth
              >
                {contacts.map((contact) => (
                  <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
                ))}
              </TextField> */}
              <InfiniteScrollSelect
                collectionName="contacts"
                label="fullName"
                onChange={handleChange}
                value={projectDetail.applicant}
                name="applicant"
                inputLabel="Applicant"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/* <TextField
                label={"Filing representative"}
                name="filingRep"
                onChange={handleChange}
                select
                value={projectDetail.filingRep}
                fullWidth
              >
                {contacts.map((contact) => (
                  <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
                ))}
              </TextField> */}
              <InfiniteScrollSelect
                collectionName="contacts"
                label="fullName"
                onChange={handleChange}
                value={projectDetail.filingRep}
                name="filingRep"
                inputLabel="Filing representative"
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              {/* <TextField
                label={"Project manager"}
                name="projectManager"
                onChange={handleChange}
                select
                value={projectDetail.projectManager}
                fullWidth
              >
                {contacts.map((contact) => (
                  <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
                ))}
              </TextField> */}
              <InfiniteScrollSelect
                collectionName="contacts"
                label="fullName"
                onChange={handleChange}
                value={projectDetail.projectManager}
                name="projectManager"
                inputLabel="Project manager"
              />
            </Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid item xs={12} md={6} lg={4}>
                <DatePicker
                  label="Start date"
                  value={
                    projectDetail.startDate
                      ? JSON.parse(projectDetail.startDate)
                      : null
                  }
                  onChange={(newValue) =>
                    handleDateChange("startDate", newValue)
                  }
                  fullWidth
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DatePicker
                  label="End date"
                  value={
                    projectDetail.endDate
                      ? JSON.parse(projectDetail.endDate)
                      : null
                  }
                  onChange={(newValue) => handleDateChange("endDate", newValue)}
                  fullWidth
                  renderInput={(params) => <TextField fullWidth {...params} />}
                />
              </Grid>
            </LocalizationProvider>
          </Grid>
        </form>
        <Box
          sx={{
            position: "fixed",
            bottom: "1rem",
            right: "1rem",
            "& > :not(style)": { m: 1 },
          }}
        >
          {!props.isInactive && (
            <Fab
              color="primary"
              aria-label="add"
              variant="extended"
              disabled={(!isDirty && !hasRequiredFields()) || props.isInactive}
              form="projectForm"
              onClick={onSubmit}
            >
              <Save sx={{ mr: 1 }} />
              Save
            </Fab>
          )}
          <Fab
            color="secondary"
            aria-label="add"
            variant="extended"
            onClick={handleCloseProject}
          >
            <Close sx={{ mr: 1 }} />
            Close
          </Fab>
        </Box>
        <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Project successfully saved!
          </Alert>
        </Snackbar>
        <Snackbar
          open={failureOpen}
          autoHideDuration={2000}
          onClose={handleFailureClose}
        >
          <Alert
            onClose={handleFailureClose}
            severity="error"
            sx={{ width: "100%" }}
          >
            Something went wrong!
          </Alert>
        </Snackbar>
        <SaveCloseDialog
          open={dialogOpen}
          saveAndClose={saveAndClose}
          discardAndClose={discardAndClose}
          setDialogOpen={setDialogOpen}
        />
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <WorkTypes
          userId={projectDetail.userId}
          db={db}
          projectId={projectId}
          contacts={contacts}
          isInactive={props.isInactive}
        />
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Documents
          documents={projectDetail?.documents || []}
          projectId={projectId}
          onSubmit={onSubmit}
          getProject={getProject}
          projectData={projectDetail}
          isInactive={props.isInactive}
        />
      </TabPanel>
      <TabPanel value={tab} index={3}>
        {!props.isInactive ? (
          <div style={{ height: "calc(100vh - 230px)" }}>
            <ProjectStatus
              thisProject={projectDetail.DOBNumber}
              workTypeIDs={workTypes.map((workType) => workType.dobNumber)}
            />
          </div>
        ) : (
          <div style={{ height: "calc(100vh - 230px)" }}>
            This feature is available with an active subscription.
          </div>
        )}
      </TabPanel>
      <TabPanel value={tab} index={4}>
        <Notes
          notes={projectDetail.notes}
          handleChangeNotes={handleChangeNotes}
          onSubmit={onSubmit}
        />
      </TabPanel>
      <TabPanel value={tab} index={5}>
        <Forms
          projectId={projectId}
          applicantDetails={applicantDetails}
          filingRepDetails={filingRepDetails}
          buildingDetails={buildingDetails}
          ownerDetails={ownerDetails}
          userId={projectDetail.userId}
          db={db}
          groupId={groupId}
          isInactive={props.isInactive}
        />
      </TabPanel>
    </>
  );
};
