import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Typography,
  styled,
  TextField,
  Paper,
  Grid,
  Box,
  Fab,
  MenuItem,
  Snackbar,
} from "@mui/material";
import AdapterDayjs from "@mui/lab/AdapterDayjs";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import MuiAlert from "@mui/material/Alert";

import { SaveCloseDialog } from "../components/SaveCloseDialog";
import { Save, Close } from "@mui/icons-material";
import { useFetchCollectionDocs } from "apis/dataSnapshots";
import { Context } from "context/Wrapper";
import { or, where } from "firebase/firestore";
import { db } from "config/config";
import InfiniteScrollSelect from "components/shared/InfiniteScrollSelect";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Item = styled(Paper)(({ theme }) => {
  return {
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
  };
});

export const ProjectNew = (props) => {
  const { projectId } = useParams();

  const context = useContext(Context);

  const { groupId, isPro, userId } = context?.state?.userProfile || {};
  const userProfile = context?.state?.userProfile || {},
    { showAllDataFlag } = context?.state || {};

  const contacts = useFetchCollectionDocs({
    collectionName: "contacts",
    conditions:
      userId && groupId && isPro && showAllDataFlag
        ? [or(where("groupId", "==", groupId), where("userId", "==", userId))]
        : userId
          ? [where("userId", "==", userId)]
          : [],
    setContext: context.setState,
  });

  const buildings = useFetchCollectionDocs({
    collectionName: "buildings",
    conditions:
      groupId && isPro && showAllDataFlag
        ? [
            or(
              where("groupId", "==", userProfile?.groupId),
              where("userId", "==", userProfile.userId),
            ),
          ]
        : [where("userId", "==", userProfile.userId)],
  });

  const navigate = useNavigate();

  const [projectDetail, setProjectDetail] = useState({});

  const [tab, setTab] = React.useState(0);

  const [open, setOpen] = React.useState(false);

  const [isDirty, setIsDirty] = React.useState(false);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const [failureOpen, setOpenFailure] = React.useState(false);

  // const userProfile = userProfileProfile;

  // console.log("userProfile", userProfile);

  const openSuccess = () => {
    setOpen(true);
  };

  const openFailure = () => {
    setOpenFailure(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleFailureClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenFailure(false);
  };

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  };

  const handleDateChange = (fieldName, value) => {
    let currentProjectDetail = projectDetail;
    setIsDirty(true);
    currentProjectDetail[fieldName] = JSON.stringify(value);
    setProjectDetail({ ...currentProjectDetail });
  };

  const saveAndClose = () => {
    onSubmit(projectDetail);
    navigate("/projects");
  };

  const handleChange = (e) => {
    let currentProjectDetail = projectDetail;
    currentProjectDetail[e.target.name] = e.target.value;
    setIsDirty(true);
    setProjectDetail({ ...currentProjectDetail });
  };

  const discardAndClose = () => {
    navigate("/projects");
  };

  const handleCloseProject = () => {
    if (isDirty) {
      setDialogOpen(true);
      return;
    }
    navigate("/projects");
  };

  const onSubmit = () => {
    db.collection("projects")
      .add({
        ...projectDetail,
        userId: userProfile.uid,
        groupId: groupId,
      })
      .then((docRef) => {
        navigate(`/projects/details/${docRef.id}`);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
      });
  };

  const hasRequiredFields = () => {
    return !!projectDetail.customerReference;
  };

  function zeroPad(num, count) {
    var numZeropad = num + "";
    while (numZeropad.length < count) {
      numZeropad = "0" + numZeropad;
    }
    return numZeropad;
  }

  const autoNumberProject = async () => {
    try {
      // Fetch all projects
      const projectsSnapshot = await db.collection("projects").get();
      const projects = projectsSnapshot.docs.map((doc) => doc.data());

      // Extract the format and create a regex from userProfile.projectNumberFormat
      const format = userProfile.projectNumberFormat;

      var base = format.replace(/#/g, "");

      const digits = format.split("#").length - 1;

      // Filter projects that match the format and extract the numerical part
      const matchingNumbers = projects
        .filter((project) => project.customerReference?.indexOf(base) !== -1)
        .map((project) =>
          parseInt(project.customerReference?.replace(base, "")),
        )
        .filter((number) => !isNaN(number));

      // Determine the next number in sequence

      var nextNumber =
        matchingNumbers.length > 0 ? Math.max(...matchingNumbers) + 1 : 1;

      if (digits.length > 0) {
        nextNumber = zeroPad(nextNumber, digits);
      }

      // Format the next number according to userProfile.projectNumberFormat

      const nextCustomerReference = base + nextNumber;

      // Set the nextCustomerReference in the state
      setProjectDetail({
        ...projectDetail,
        customerReference: nextCustomerReference ? nextCustomerReference : "",
      });
    } catch (error) {
      console.error("Error adding document: ", error);
      openFailure();
    }
  };

  useEffect(() => {
    if (userProfile.autonumber) autoNumberProject();
  }, [userProfile.autonumber]);

  return (
    <>
      <Typography
        sx={{ textTransform: "uppercase", marginBottom: "1rem" }}
        align="left"
        variant="h4"
      >
        New PROJECT
      </Typography>

      <form name="projectForm" id="projectForm">
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              label="Job ID"
              variant="outlined"
              name="customerReference"
              value={projectDetail.customerReference}
              onInput={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {/* <TextField
              label={"Building"}
              name="building"
              select
              onChange={handleChange}
              value={projectDetail.building}
              fullWidth
            >
              {buildings.map((building) => (
                <MenuItem value={building.id}>
                  {building.customerReference}
                </MenuItem>
              ))}
            </TextField> */}
            <InfiniteScrollSelect
                collectionName="buildings"
                label="customerReference"
                onChange={handleChange}
                value={projectDetail.building}
                name="building"
                inputLabel="Building"
              />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <TextField
              name="DOBNumber"
              label="DOB reference (BIS or Now!)"
              variant="outlined"
              value={projectDetail.DOBNumber}
              onInput={handleChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {/* <TextField
              label={"Client"}
              name="client"
              onChange={handleChange}
              value={projectDetail.client}
              select
              fullWidth
            >
              {contacts.map((contact) => (
                <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
              ))}
            </TextField> */}
            <InfiniteScrollSelect
                collectionName="contacts"
                label="fullName"
                onChange={handleChange}
                value={projectDetail.client}
                name="client"
                inputLabel="Client"
              />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {/* <TextField
              label={"Applicant"}
              name="applicant"
              onChange={handleChange}
              select
              value={projectDetail.applicant}
              fullWidth
            >
              {contacts.map((contact) => (
                <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
              ))}
            </TextField> */}
            <InfiniteScrollSelect
                collectionName="contacts"
                label="fullName"
                onChange={handleChange}
                value={projectDetail.applicant}
                name="applicant"
                inputLabel="Applicant"
              />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {/* <TextField
              label={"Filing representative"}
              name="filingRep"
              onChange={handleChange}
              select
              value={projectDetail.filingRep}
              fullWidth
            >
              {contacts.map((contact) => (
                <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
              ))}
            </TextField> */}
            <InfiniteScrollSelect
                collectionName="contacts"
                label="fullName"
                onChange={handleChange}
                value={projectDetail.filingRep}
                name="filingRep"
                inputLabel="Filing representative"
              />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {/* <TextField
              label={"Project manager"}
              name="projectManager"
              onChange={handleChange}
              select
              value={projectDetail.projectManager}
              fullWidth
            >
              {contacts.map((contact) => (
                <MenuItem value={contact.id}>{contact.fullName}</MenuItem>
              ))}
            </TextField> */}
            <InfiniteScrollSelect
                collectionName="contacts"
                label="fullName"
                onChange={handleChange}
                value={projectDetail.projectManager}
                name="projectManager"
                inputLabel="Project manager"
              />
          </Grid>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Grid item xs={12} md={6} lg={4}>
              <DatePicker
                label="Start date"
                value={
                  projectDetail.startDate
                    ? JSON.parse(projectDetail.startDate)
                    : null
                }
                onChange={(newValue) => handleDateChange("startDate", newValue)}
                fullWidth
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DatePicker
                label="End date"
                value={
                  projectDetail.endDate
                    ? JSON.parse(projectDetail.endDate)
                    : null
                }
                onChange={(newValue) => handleDateChange("endDate", newValue)}
                fullWidth
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
          </LocalizationProvider>
        </Grid>
      </form>
      <SaveCloseDialog
        open={dialogOpen}
        saveAndClose={saveAndClose}
        discardAndClose={discardAndClose}
        setDialogOpen={setDialogOpen}
        isInactive={props.isInactive}
      />

      <Box
        sx={{
          position: "fixed",
          bottom: "1rem",
          right: "1rem",
          "& > :not(style)": { m: 1 },
        }}
      >
        {!props.isInactive && (
          <Fab
            color="primary"
            aria-label="add"
            variant="extended"
            onClick={onSubmit}
            form="contactForm"
            disabled={(!isDirty && !hasRequiredFields()) || props.isInactive}
          >
            <Save sx={{ mr: 1 }} />
            Save
          </Fab>
        )}
        <Fab
          color="secondary"
          aria-label="add"
          variant="extended"
          onClick={handleCloseProject}
        >
          <Close sx={{ mr: 1 }} />
          Close
        </Fab>
      </Box>
      <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          Contact successfully saved!
        </Alert>
      </Snackbar>
      <Snackbar
        open={failureOpen}
        autoHideDuration={2000}
        onClose={handleFailureClose}
      >
        <Alert
          onClose={handleFailureClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Something went wrong!
        </Alert>
      </Snackbar>
    </>
  );
};
