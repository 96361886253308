import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Typography, styled } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import Fab from "@mui/material/Fab";
import SaveIcon from '@mui/icons-material/Save';

import AddIcon from "@mui/icons-material/Add";
import Delete from "@mui/icons-material/Delete";
import Clear from "@mui/icons-material/Clear";
import { DeleteDialog } from "../components/DeleteDialog";

import { Link, Navigate } from "react-router-dom";

import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";

import {
  collection,
  query,
  setDoc,
  doc,
  onSnapshot,
  or,
  where,
} from "firebase/firestore";
import { Context } from "context/Wrapper";
import { useAdvancedDataListener, useFetchCollectionDocs } from "apis/dataSnapshots";
import { db } from "config/config";
import AdvancedTable from "components/AdvancedTable";
import useCustomParams from "components/shared/useCustomParams";
import tableConfig from "config/advancedTableColumnsControl.json";


const searchLimit = tableConfig?.searchMinLength;

// const ListItem = styled(Paper)(({ theme, ...rest }) => {
//   return {
//     width: "100%",
//     padding: theme.spacing(1),
//     textAlign: "left",
//     marginTop: theme.spacing(2),
//     marginBottom: 0,
//     color: theme.palette.primary.main,
//     background: theme.palette.secondary.main,
//   };
// });

let filterSearchedTimeout = null;

export const Profiles = (props) => {
  const context = useContext(Context);
  const { params, updateParams } = useCustomParams();

  // const { groupId, isPro, subscription, subscriptionType, email } = context?.state?.userProfile || {};

  const user = context?.state?.userProfile || {},
    { showAllDataFlag } = context?.state || {};


  // const data = useFetchCollectionDocs({
  //   collectionName: "profiles",
  //   conditions: [true],
  // });

  const groups = useFetchCollectionDocs({
    collectionName: "groups",
    conditions: [true],
  });

  const [userProfiles, setuserProfiles] = useState([]);

  const navigate = useNavigate();

  const [deleteOpen, setDeleteOpen] = useState(false);

  const [itemToDelete, setItemToDelete] = useState({
    itemId: "",
    identifier: "",
  });

  const [filtereduserProfiles, setFiltereduserProfiles] = useState([]);

  const [searchValue, setSearchValue] = useState("");

  const [loading, setLoading] = useState(false);

  // function handleClick() {
  //   setLoading(true);
  // }

  // useEffect(() => {
  //   const q = query(collection(db, "profiles"));

  //   const unsubscribe = onSnapshot(q, (querySnapshot) => {
  //     const newuserProfiles = querySnapshot.docs.map((doc) => {
  //       const data = doc.data();
  //       return { ...data, id: doc.id };
  //     });
  //     setuserProfiles(newuserProfiles);
  //   });

  //   return () => unsubscribe();
  // }, []);


  let { data, count } = useAdvancedDataListener({
    user,
    // conditions,
    // setLastRef: setLastVisible,
    fetchAll: true,
    collectionName: "profiles",
    // rowsLimit,
    // params,
  });




  useEffect(() => {
    updateParams({
      page: "",
      search: "",
      pageIncreasing: "",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setuserProfiles(data);
  }, [userProfiles, data]);


  // const handleInput = (e) => {
  //   let str = e.target.value;

  //   setSearchValue(str);

  //   const newArr = profiles
  //     .filter(
  //       (item) =>
  //         item.fullName?.toLowerCase().includes(str.toLowerCase()) ||
  //         item.businessName?.toLowerCase().includes(str.toLowerCase()) ||
  //         item.phone?.toLowerCase().includes(str.toLowerCase()) ||
  //         item.email?.toLowerCase().includes(str.toLowerCase())
  //     )
  //     .map((item) => {
  //       let newFullName =
  //         item.fullName &&
  //         item.fullName.replace(
  //           new RegExp(str, "gi"),
  //           (match) =>
  //             `<mark style="background: #2769AA; color: white;">${match}</mark>`
  //         );
  //       let newPhone =
  //         item.phone &&
  //         item.phone.replace(
  //           new RegExp(str, "gi"),
  //           (match) =>
  //             `<mark style="background: #2769AA; color: white;">${match}</mark>`
  //         );
  //       let newBusinessName =
  //         item.businessName &&
  //         item.businessName.replace(
  //           new RegExp(str, "gi"),
  //           (match) =>
  //             `<mark style="background: #2769AA; color: white;">${match}</mark>`
  //         );
  //       let newEmail =
  //         item.email &&
  //         item.email.replace(
  //           new RegExp(str, "gi"),
  //           (match) =>
  //             `<mark style="background: #2769AA; color: white;">${match}</mark>`
  //         );
  //       return {
  //         ...item,
  //         fullName: newFullName,
  //         phone: newPhone,
  //         businessName: newBusinessName,
  //         email: newEmail,
  //       };
  //     });

  //   setFiltereduserProfiles(newArr);
  // };

  useEffect(() => {
    if (
      (searchValue?.length < searchLimit && searchValue?.length !== 0) ||
      !searchValue
    ) {
      if (searchValue?.length === 0) {
        updateParams({ search: "", pageSize: params.get("pageSize") });
      }
      return;
    }
    if (filterSearchedTimeout !== null) clearTimeout(filterSearchedTimeout);

    filterSearchedTimeout = setTimeout(() => {
      updateParams({ search: searchValue, pageSize: params.get("pageSize") });

      const newArr = userProfiles
        .filter(
          (item) => {

            return item.email?.toLowerCase().includes(searchValue.toLowerCase()) ||
              item.subscription?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
              item.subscriptionType?.toString().toLowerCase().includes(searchValue.toLowerCase())
             || item.expirationDate?.toString().toLowerCase().includes(searchValue.toString().toLowerCase()) || item.groupId?.toString().toLowerCase().includes(searchValue.toString().toLowerCase()) 
          }

        )
        .map((item) => {
          let newEmail =
            item.email &&
            item.email.replace(
              new RegExp(searchValue, "gi"),
              (match) =>
                `<mark style="background: #2769AA; color: white;">${match}</mark>`
            );

          let newSubscription =
            item.subscription &&
            item.subscription.replace(
              new RegExp(searchValue, "gi"),
              (match) =>
                `<mark style="background: #2769AA; color: white;">${match}</mark>`
            );
          let newSubscriptionType =
            item.subscriptionType &&
            item.subscriptionType.replace(
              new RegExp(searchValue, "gi"),
              (match) =>
                `<mark style="background: #2769AA; color: white;">${match}</mark>`
            );

          let newExpirationDate =
            item.expirationDate &&
            item.expirationDate.toString().replace(
              new RegExp(searchValue, "gi"),
              (match) =>
                `<mark style="background: #2769AA; color: white;">${match}</mark>`
            );

          let newGroupId =
            item.groupId &&
            item.groupId.toString().replace(
              new RegExp(searchValue, "gi"),
              (match) =>
                `<mark style="background: #2769AA; color: white;">${match}</mark>`
            );

          return {
            ...item,
            email: newEmail,
            groupId: newGroupId,
            subscription: newSubscription,
            subscriptionType: newSubscriptionType,
            expirationDate: newExpirationDate,
          };
        });

      setFiltereduserProfiles(newArr);
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfiles, searchValue]);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
  };

  // const createMarkup = (html) => {
  //   return { __html: html };
  // };

  // const handleTrackChange = async (event, id) => {
  //   const thisContact = doc(db, "userProfiles", id);
  //   setDoc(thisContact, { track: event.target.checked }, { merge: true }).then(
  //     () => {
  //       console.log("updated");
  //     }
  //   );
  // };

  
  const searchCheck = searchValue.length >= searchLimit;
  const [profilesCollection,setprofilesCollection] = useState([]);
  const [rowCount,setRowCount] = useState();


  useEffect(()=>{
    if(searchCheck){
      setprofilesCollection(filtereduserProfiles);
      setRowCount(profilesCollection?.length)

    } else{    
      setprofilesCollection(userProfiles);
      setRowCount(count);
    }
  },[userProfiles,filtereduserProfiles,searchCheck,rowCount])



  const handleDelete = (id, identifier, row) => {
    setItemToDelete({ itemId: id, identifier: row.email });
    setDeleteOpen(true);
  };

  const confirmDelete = async () => {
    setLoading(true);
    await db.collection("profiles").doc(itemToDelete.itemId).delete();
    // Delete all related data from other collections
    await deleteUserRelatedData(itemToDelete.itemId);
    let filterdProjetcs = profilesCollection.filter((profile)=>profile.id !== itemToDelete.itemId);
    setprofilesCollection(filterdProjetcs);
    setRowCount((prevCount)=>prevCount-1);
    setItemToDelete({ itemId: "", identifier: "" });
    setDeleteOpen(false);
  };

  const deleteUserRelatedData = async (userId) => {
    // List of collections that contain data related to the user
    const collectionsToDeleteFrom = ["buildings", "jobs", "contacts", "notes", "documentTypes", "filings", "projects", "todos", "workTypes"];

    const batch = db.batch();

    // Loop through each collection and delete the related documents
    for (const collectionName of collectionsToDeleteFrom) {
      const snapshot = await db.collection(collectionName).where("userId", "==", userId).get();

      snapshot.forEach((doc) => {
        batch.delete(doc.ref);
      });
    }

    // Commit the batch deletion
    await batch.commit();
  };

  if (
    user.uid !== process.env.REACT_APP_DEV_FIREBASE_ADMIN_ID &&
    user.uid !== process.env.REACT_APP_PROD_FIREBASE_ADMIN_ID &&
    user.uid !== process.env.REACT_APP_PROD_FIREBASE_ADMIN_ID_ALT &&
    user.uid !== process.env.REACT_APP_DEV_FIREBASE_ADMIN_ID_ALT &&
    user.uid !== process.env.REACT_APP_DEV_FIREBASE_ADMIN_ID_ALT2 &&
    user.uid !== process.env.REACT_APP_DEV_FIREBASE_ADMIN_ID_ALT3 &&
    user.uid !== process.env.REACT_APP_DEV_FIREBASE_ADMIN_ID_ALT4
 
  ) {
    return "You are not authorized to view this page.";
  }


  return (
    <>
      <Fade in={true}>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={8} style={{ position: "sticky" }}>
              <Typography align="left" variant="h4">
                User Profiles
              </Typography>
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Paper
                component="form"
                onSubmit={(e) => e.preventDefault()}
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginLeft: "auto",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search"
                  value={searchValue}
                  inputProps={{ "aria-label": "search" }}
                  onInput={handleSearch}
                />
                {searchValue.length > 0 ? (
                  <IconButton
                    sx={{ p: "10px" }}
                    aria-label="search"
                    onClick={() => {
                      setSearchValue("");
                      updateParams({ search: "" });
                      setFiltereduserProfiles([]);
                    }}
                  >
                    <Clear />
                  </IconButton>
                ) : (
                  <IconButton sx={{ p: "10px" }} aria-label="clear">
                    <SearchIcon />
                  </IconButton>
                )}
              </Paper>
            </Grid>
          </Grid>

          {
            /*
                      <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={2}
                      textAlign={"left"}
                      padding={"1rem 1rem 0 1rem"}
                      sx={{ display: { xs: "none", md: "flex" } }}
                    >
                      <Grid item xs={12} md={3}>
                        <span>Email</span>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <span>Group</span>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <span>Subscription</span>
                      </Grid>
                      <Grid item xs={12} md={2} lg={2}>
                        <span>Subscription Type</span>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <span>Expiration Date</span>
                      </Grid>
          
                      <Grid item xs={12} md={1} lg={1}>
                        <span>Delete</span>
                      </Grid>
                    </Grid>
          
                             {profilesCollection.map((contact) => (
                      <ListItem>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-start"
                          alignItems="center"
                          spacing={2}
                        >
                          <Grid item xs={12} md={3}>
                            <Button
                              variant="contained"
                              style={{
                                width: "100%",
                                justifyContent: "flex-start",
                                whiteSpace: "wrap",
                                wordBreak: "break-all",
                              }}
                              color={"primary"}
                              component={Link}
                              to={`/userProfiles/details/${contact.id}`}
                            >
                              <span
                                dangerouslySetInnerHTML={createMarkup(contact.email)}
                              />
                            </Button>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <span
                              dangerouslySetInnerHTML={createMarkup(
                                groups?.find((g) => g?.id === contact.groupId)
                                  ?.groupName || contact.groupId
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <span
                              dangerouslySetInnerHTML={createMarkup(contact.subscription)}
                            />
                          </Grid>
                          <Grid item xs={12} md={2} lg={2}>
                            <span
                              dangerouslySetInnerHTML={createMarkup(
                                contact.subscriptionType
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <span
                              dangerouslySetInnerHTML={createMarkup(
                                contact.expirationDate
                              )}
                            />
                          </Grid>
          
                          <Grid item xs={1} md={1} lg={1}>
                            <IconButton>
                              <Delete
                                onClick={() => handleDelete(contact.id, contact.email)}
                              />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
            */
          }


          <AdvancedTable
            data={profilesCollection}
            collectionName="profiles"
            columnsRaw={[
              // {
              // field: "id",
              // headerName: "ID",
              // },
              {
                field: "email",
                headerName: "Email",
                renderCell: ({ row }) => (
                  <div dangerouslySetInnerHTML={{ __html: row.email }}></div>
                ),
              },
              {
               

                field: "groupId",
                headerName: "Group",
                renderCell: ({ row }) => (
                  <div dangerouslySetInnerHTML={{ __html: groups?.find((g) => g?.id === row.groupId)
                    ?.groupName || row.groupId }}></div>
                ),
              },
              {
                field: "subscription",
                headerName: "Subscription",
                renderCell: ({ row }) => (
                  <div
                    dangerouslySetInnerHTML={{ __html: row.subscription }}
                  ></div>
                ),
              },
              {
                field: "subscriptionType",
                headerName: "Subscription Type",
                renderCell: ({ row }) => (
                  <div dangerouslySetInnerHTML={{ __html: row.subscriptionType }}></div>
                ),
              },
              {
                field: "expirationDate",
                headerName: "Expiration Date",
                renderCell: ({ row }) => (
                  <div
                    dangerouslySetInnerHTML={{ __html: row.expirationDate }}
                  ></div>
                ),
              },
            ]}
            rowCount={rowCount}
            handleDelete={handleDelete}
          />

        </div>
      </Fade>
      <DeleteDialog
      open={deleteOpen}
      confirmDelete={confirmDelete}
      setDialogOpen={setDeleteOpen}
      identifier={itemToDelete.identifier}
      loading={loading}
      />

    </>
  );
};
